.authPagesSection {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100vh;

    h1 {
        font-size: 34px;
        font-family: @primary-font;
        color: @default-color;
    }

    h2 {
        font-size: 24px;
        font-family: @primary-font;
        color: @black-color;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .authLeft {
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background-color: @altralightgrey-color;
        padding: 30px;
        align-items: center;
        justify-content: center;
        text-align: center;

        .thumb {
            img {
                max-width: 100%;
            }
        }

        p {
            font-size: 20px;
            font-weight: 400;
            color: @grey-color;
        }

        .contentWrap {
            max-width: 380px;
        }
        @media screen and(max-width:600px) {
           display:none; 
        }
    }

    .authRight {
        width: 50%;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content:center;
        flex-direction: column;
        .logo__wrapper{
            justify-content: center;
        }
        @media screen and(max-width:600px) {
           width:100%;
         }
    }

    .formWrap {
        max-width: 400px;
        min-width: 400px;
        margin: 0 auto;
        height: calc(100% - 120px);
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: column;

        .ant-card-body {
            border: none;
            padding: 0;
        }

        .passwordField {
            .ant-form-item-extra {
                text-align: right;
                font-size: 14px;
                font-weight: 600;
                font-family: @primary-font;
                color: @primary-color;
                padding: 10px 0 0;
                position: relative;
                right:5px;
            }
        }

        button {
            &.ant-btn {
                width: 100%;
                padding: 15px;
                font-size: 16px;
                font-weight: 600;
                height: auto;
                line-height: 1.3;
            }
        }

        .registerNowText {
            margin: 25px 0 0;
            text-align: center;

            p {
                font-size: 16px;
                font-weight: 400;
                font-family: @primary-font;
                color: @black-color;
                margin: 0;

                a {
                    font-weight: 600;
                    color: @primary-color;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .reminderMsg {
            margin-bottom: 10px;

            span {
                font-family: @secondary-font;
                line-height: 1.2;
            }
        }
        .ant-input{
            padding: 20px 20px 5px 16px !important;
        }
        @media screen and(max-width:600px) {
           max-width:100%;
           min-width:80%;
           width:80%;
           height: calc(100% - 190px);
         }
         @media screen and(max-width:400px){
            height: calc(100% - 220px);
         }
         .rememberMe{
            span{
                font-weight: 600;
                font-family: @primary-font;
            }
           
         }
         .formRow{
            justify-content:space-between;
            padding:0px 5px;

            .forgetPassword{
                font-weight: 600;
                font-family: @primary-font;
            }

         }
    }

    .ant-form-item-control-input-content {

        .ant-input-group {
            span.ant-input-group-addon {
                background: no-repeat;
                border: 0;
                position: absolute;
                z-index: 9;
                transform-origin: 0 0;
                top: 50%;
                transform: translate(0, -50%);
                padding-left: 14px;

            }

            .ant-input {
                &::placeholder {
                    color: transparent;
                }
                &:focus{
                    box-shadow: 0 0 0 1px #4a5cfb;
                }

            }
        }

        input:focus~span.ant-input-group-addon,
        input:not(:placeholder-shown)~span.ant-input-group-addon {
            transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
            top: 25px;
            font-size: 12px;
        }


        // -----------------------------------------------------------------------------------------------------------------------------------------------------
        // -------------------------------------------------password label floating-----------------------------------------------------------------------------
        .ant-input-password-large.ant-input-group-wrapper-status-success .ant-input-group-addon,
        .ant-input-password-large.ant-input-group-wrapper-status-error .ant-input-group-addon {
            transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
            top: 25px;
            font-size: 12px;
        }
    }
   .ant-input-affix-wrapper-focused{
    box-shadow: 0 0 0 1px #4a5cfb;
   }
   
    .ant-form .ant-form-item {
        .ant-form-item-control-input {
            .ant-input-affix-wrapper-lg {
                padding: 0;
                border: none;
                border-radius: 12px;

                .ant-input-suffix {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 999;
                }
            }
        }
    }
}
