.teamListingSection{
	width: calc(100% - 24px);
	padding: 20px 0px 0px 24px;
	.listItem{
		width: 100%;
		background-color: @white-color;
		border-radius: 14px;
		box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
		margin-bottom: 15px;
		padding: 24px;
		.ant-row{
			align-items: center;
		}
		.userInfoWrap{
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			.thumb{
				img{
					max-width: 52px;
					border-radius: 50%;
				}
			}
			.content{
				padding-left: 15px;
				word-break: break-word;
				h4{
					margin-bottom: 5px;
				}
				span{
					color: @grey-color;
					font-size: 14px;
					font-family: @primary-font;
					font-weight: 400;
					margin-left: 5px;
				}
				.point{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					gap: 7px;
					font-size: 14px;
					font-weight: 600;
					font-family: @primary-font;
					color: @primary-color;
					i{
						width: 18px;
						height: 18px;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;
						background-color: @primary-color;
						border-radius: 50%;
						svg{
							margin: 0 auto;
						}
					}
				}
				a{
					word-break:break-word;
				}
			}
		}
		ul{
			&.clientsList{
				margin: 0;
				padding: 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				li{
					list-style: none;
					position: relative;
					font-size: 14px;
					font-weight: 400;
					font-family: @primary-font;
					color: @grey-color;
					padding: 5px 0;
					padding-right: 20px;
					margin-right: 20px;
					display: inline-block;
					&::after{
						content: "";
						position: absolute;
						right: 0;
						top: 0;
						width: 1px;
						height: 100%;
						background-color: @altralight-color;
					}
					&:last-child{
						padding-right: 0;
						margin-right: 0;
						&::after{
							display: none;
						}
					}
					span{
						font-weight: 700;
						color: @default-color;
					}
				}
			}
		}
		.activityWrap{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;
			.activity{
				text-align: right;
				.btnLink{
					display: inline-block;
				}
			}
			p{
				padding: 0;
				margin-bottom: 8px;
				font-size: 12px;
				font-family: @primary-color;
				color: @grey-color;
			}
			.achivement{
				margin-right: 40px;
				ul{
					margin: 0;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					li{
						list-style: none;
						position: relative;
						text-align: center;
						margin: 0 10px;
						span{
							position: absolute;
							left: 0;
							top: 50%;
							right: 0;
							margin: -5px auto 0;
							transform: translateY(-50%);
							line-height: 1;
							font-size: 14px;
							font-family: @secondary-font;
							color: @default-color;
						}
					}
				}
			}
		}
		.listItemDetails{
			width: 100%;
			background-color: @lighgrey-color;
			border-radius: 10px;
			max-height: 235px;
			margin: 20px 0 0 0;
			padding: 10px 20px;
            transition: max-height 0.50s ease-out;
            overflow: hidden;
			ul{
				margin: 0;
				padding: 0;
				li{
					list-style: none;
					font-size: 14px;
					font-weight: 400;
					font-family: @primary-font;
					color: @default-color;
					padding: 15px 0;
					border-bottom: 1px solid @altralight-color;
					position: relative;
					&:last-child{
						border-bottom: none;
					}
					span{
						color: @primary-color;
						font-weight: 600;
					}
					.date{
						font-size: 14px;
						color: @grey-color;
						font-weight: 400;
						position: absolute;
						right: 0;
						top: 10px;
					}
				}
			}
		}
	}

	.inviteMember{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 15px 0;
		p{
			font-size: 18px;
			font-weight: 400;
			font-family: @secondary-font;
			color: @black-color;
			margin: 0;
		}
		button{
			&.btn-primary{
				font-size: 16px;
				padding: 12px 25px;
				height: auto;
				svg{
					margin-left: 10px;
				}
			}
		}
	}
	.teamItemWrap{margin-bottom: 30px;}
	.editListItem{
		width: 100%;
		background-color: @white-color;
		border-radius: 14px;
		box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
		margin-bottom: 15px;
		padding: 24px;
		.ant-row{
			align-items: center;
			height: 100%;
		}
		.editWrap{
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;
   			// max-width: 640px;
			max-width:700px;
		.userInfoWrap{
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			margin-right: 70px;
			.thumb{
				img{
					max-width: 52px;
					border-radius: 50%;
				}
			}
			.content{
				padding-left: 15px;
				word-wrap: break-word;
				h4{
					margin-bottom: 5px;
				}
				a{
					font-size: 14px;
					color: @grey-color;
					font-family: @secondary-font;
					word-break: break-all;
					&:hover{
						color: @primary-color;
					}
				}
			}
		}
		.selectWrap{
			margin-bottom:0;
			.ant-select-selector{
				height:40px;
			}
		}
	}
	.iconBtn{
		text-align: -webkit-right;
		a{
			svg{
				path{
					.transition;
				}
			}
			&:hover{
				svg{
					path{
						fill: @primary-color;
					}
				}
			}
		}
	}
	.btnWrap{
		text-align: right;
		display: block;
		button{
			display: inline-block;
		}
	}
	}
}

iframe{
	z-index: -99999 !important;
}
.tabFormWrap{
	.ant-select-focused{
		box-shadow: 0 0 0 1px #4a5cfb;
		border-radius:12px;
	}
}


.memberListingSection{
	width: 100%;
	.listItem{
		width: 100%;
		background-color: @white-color;
		border-radius: 14px;
		box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
		margin-bottom: 15px;
		padding: 24px;
		.ant-row{
			align-items: center;
		}
		.userInfoWrap{
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			.thumb{
				img{
					max-width: 52px;
					border-radius: 50%;
				}
			}
			.content{
				padding-left: 15px;
				word-break: break-word;
				h4{
					margin-bottom: 5px;
				}
				span{
					color: @grey-color;
					font-size: 14px;
					font-family: @primary-font;
					font-weight: 400;
					margin-left: 5px;
				}
				.point{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					gap: 7px;
					font-size: 14px;
					font-weight: 600;
					font-family: @primary-font;
					color: @primary-color;
					i{
						width: 18px;
						height: 18px;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;
						background-color: @primary-color;
						border-radius: 50%;
						svg{
							margin: 0 auto;
						}
					}
				}
				a{
					word-break:break-word;
				}
			}
		}
		ul{
			&.clientsList{
				margin: 0;
				padding: 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				li{
					list-style: none;
					position: relative;
					font-size: 14px;
					font-weight: 400;
					font-family: @primary-font;
					color: @grey-color;
					padding: 5px 0;
					padding-right: 20px;
					margin-right: 20px;
					display: inline-block;
					&::after{
						content: "";
						position: absolute;
						right: 0;
						top: 0;
						width: 1px;
						height: 100%;
						background-color: @altralight-color;
					}
					&:last-child{
						padding-right: 0;
						margin-right: 0;
						&::after{
							display: none;
						}
					}
					span{
						font-weight: 700;
						color: @default-color;
					}
				}
			}
		}
		.activityWrap{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;
			.activity{
				text-align: right;
				.btnLink{
					display: inline-block;
				}
			}
			p{
				padding: 0;
				margin-bottom: 8px;
				font-size: 12px;
				font-family: @primary-color;
				color: @grey-color;
			}
			.achivement{
				margin-right: 40px;
				ul{
					margin: 0;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					li{
						list-style: none;
						position: relative;
						text-align: center;
						margin: 0 10px;
						span{
							position: absolute;
							left: 0;
							top: 50%;
							right: 0;
							margin: -5px auto 0;
							transform: translateY(-50%);
							line-height: 1;
							font-size: 14px;
							font-family: @secondary-font;
							color: @default-color;
						}
					}
				}
			}
		}
		.listItemDetails{
			width: 100%;
			background-color: @lighgrey-color;
			border-radius: 10px;
			max-height: 235px;
			margin: 20px 0 0 0;
			padding: 10px 20px;
            transition: max-height 0.50s ease-out;
            overflow: hidden;
			ul{
				margin: 0;
				padding: 0;
				li{
					list-style: none;
					font-size: 14px;
					font-weight: 400;
					font-family: @primary-font;
					color: @default-color;
					padding: 15px 0;
					border-bottom: 1px solid @altralight-color;
					position: relative;
					&:last-child{
						border-bottom: none;
					}
					span{
						color: @primary-color;
						font-weight: 600;
					}
					.date{
						font-size: 14px;
						color: @grey-color;
						font-weight: 400;
						position: absolute;
						right: 0;
						top: 10px;
					}
				}
			}
			
		}	
	}	

	.inviteMember{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 15px 0;
		p{
			font-size: 18px;
			font-weight: 400;
			font-family: @secondary-font;
			color: @black-color;
			margin: 0;
		}
		button{
			&.btn-primary{
				font-size: 16px;
				padding: 12px 25px;
				height: auto;
				svg{
					margin-left: 10px;
				}
			}
		}
	}
	.teamItemWrap{
		margin-bottom: 30px;
		h5{
			font-size: 16px;
			font-weight: 600;
			color: @grey-color;
			margin-bottom: 10px;
		}
	}
	.editListItem{
		width: 100%;
		background-color: @white-color;
		border-radius: 14px;
		box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
		margin-bottom: 15px;
		padding: 20px;
		.ant-row{
			align-items: center;
			height: 100%;
		}
		.editWrap{
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: space-between;
   			// max-width: 640px;
			max-width:700px;
		.userInfoWrap{
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			margin-right: 30px;
			.thumb{
				img{
					max-width: 52px;
					border-radius: 50%;
				}
			}
			.content{
				padding-left: 15px;
				word-wrap: break-word;
				h4{
					margin-bottom: 5px;
				}
				a{
					font-size: 14px;
					color: @grey-color;
					font-family: @secondary-font;
					word-break: break-all;
					&:hover{
						color: @primary-color;
					}
				}
			}
		}
		.selectWrap{
			margin-bottom:0;
			.ant-select-selector{
				height:40px;
			}
		}
	}
	.iconBtn{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		a{
			display: inherit;
			&:hover{
				svg{
					path{
						fill: @primary-color;
					}
				}
			}
		}
		.btnLink{
			margin-right: 25px;
			&::after{
				display: none;
			}
		}
	}
	.btnWrap{
		text-align: right;
		display: block;
		button{
			display: inline-block;
		}
	}

	
	

	}
}

.listItemDetails{
	
	.listItemDetailsSC{
		height:300px;
		max-height:300px;
		overflow:auto;

	}
	.checkWrap{
			// padding: 25px 0 15px;
			padding:15px;
			border-bottom: 1px solid @altralight-color;
			max-width: 300px;
			&:last-child{
				border-bottom: none;
			}
			.checkTitle{
				margin-bottom: 5px;
				
				label{
					font-size: 16px;
					font-weight: 600;
					color: @default-color;
					font-family: @primary-font;
					padding-left:0px;
				}
			}
			.ant-checkbox-group{
				padding-left: 25px;
				.ant-checkbox-group-item{
					font-size: 16px;
					color: @grey-color;
					margin: 0 0 12px 0;
					font-family: @primary-font;
				}
			}
			label{
				padding-left:24px;
			}
		}
		h4{
			word-break: break-all;
		}
		.teamSaveBtn{
			padding: 0px 10px;
		display: flex;
		align-items: center;
		justify-content:flex-end;
		button{
			width:150px;
			background-color:@primary-color;
			color:#fff;
			height:40px;
			span{
				font-size:14px;
			}
		}
		}
	}