.main__page__wrapper {
	&.has__header {
		.main__page__content {
			padding-top: @layout-header-height;

			&.padding-0 {
				padding: 0;
			}
		}
	}

	&.has__appsidebar {
		.main__page__content {
			display: flex;
			flex-direction: column;
			background-color: @layout-content-background;
			height: 100%;
			min-height: 100vh;
		}
	}

	.main__app__content {
		display: flex;
		flex-direction: column;

		.app__container {
			display: flex;
			flex-direction: column;
			padding-left: @gutter;
			padding-right: @gutter;
			padding-bottom: @gutter;

			&__card {
				height: 100%;
			}
		}
	}
}

.mainAppContent {
	background-color: @body-bg;
	// padding: 30px 24px 24px 24px;
	// padding: 15px 24px 12px 24px;

	.modContainerDashboard {
		padding: 15px 24px 12px 24px;

		@media screen and(max-width:1560px) {
			padding: 15px;
		}

		.mainGraphViewContainer {
			max-width: 100%;
			width: 700px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.settingSection {
		.tabFormWrap {
			h2 {
				font-family: @primary-font;
				font-size: 20px;
				font-weight: 400;
				text-transform: capitalize;
			}
		}
	}
}

// --ant-modal

.ant-modal {
	.ant-modal-footer {
		button {
			background-color: @primary-color;
			width: 100%;
			height: 50px;
			color: white;
			margin-bottom: 10px;
			margin-left: 0px !important;

			span {
				.mediumFont();
				font-size: 14px;
			}
		}

		border-top: none;
	}
}

// ------------------------------------------refer client info css-------------------------------------------------------------------------
.referClientPoint {
	.clientInfo {
		.ant-col {
			padding: 0px 6px 0px 0px;
		}

		.referClientBtn {
			background-color: @primary-color;
			height: 50px;
			color: #fff;
			border-radius: 10px;

			span {
				font-size: 16px;
				.mediumFont();
			}
		}

		h3 {
			font-size: 16px;
			margin: 20px 0px;
			.mediumFont();
		}
	}

	.refferForm {
		.ant-input {
			font-size: 14px;
			color: #222325;
			.mediumFont();
			height: 50px;
			border: none;
			display: flex;
			flex-wrap: wrap;
			padding: 20px 20px 5px 16px !important;
			align-items: center;
			background-color: #fff !important;
			padding: 0 15px;
			transition: all 0.5s ease-in-out;
			-o-transition: all 0.5s ease-in-out;
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			border-radius: 12px;
		}

		.contentInput {
			position: relative;

			label {
				position: absolute;
				z-index: 99;
				top: 12px;
				left: 16px;
			}

			&:focus {
				label {
					transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
				}
			}
		}
	}

	.ant-divider-horizontal {
		background-color: @altralight-color;
	}

	.clientListing {
		h3 {
			font-size: 16px;
			margin: 20px 0px;
			.mediumFont();
		}

		.ag-row {
			border: none;

			// margin-top:20px;
			border-radius: 10px;
		}

		.ag-cell {
			line-height: 50px;
			background-color: @lighgrey-color;
			height: 50px;
		}

		.ag-paging-panel {
			border: none;
		}

		.ag-row-hover {
			background-color: white !important;
		}

		.ag-cell-range-left {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		.ag-cell-range-right {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		.ag-theme-alpine {
			.ag-row-hover {
				&::before {
					background-color: #fff !important;
				}
			}
		}

		.referredTag {
			margin: 0px 0px !important;
			padding: 4px 10px !important;
		}
	}
}

.ant-dropdown {
	.deleteIcon {
		display: flex;
		align-items: center;

		span {
			.semiMediumFont();
			color: #000;
			margin-left: 10px;
		}
	}
}

.ag-grid-wrapper {
	height: 100%;
	min-height: 400px;
}

.logo__wrapper {
	display: flex;
	align-items: center;

	img {
		max-height: 100%;
		max-width: 100%;

		&+span {
			margin-left: @logo-space;
		}
	}

	span {
		white-space: nowrap;
		font-size: @logo-text-size;
		font-weight: bold;
		color: @text-color;
	}
}

.fullscreen__spinner__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.section {
	padding: @section-padding;
}

.section__big__title {
	display: block;
	font-weight: bold;
	font-size: 56px;
	color: @text-color;
}

.section__subtitle {
	display: block;
	font-size: 24px;
	color: @text-color-secondary;
}

.ant-card {
	.card__title {
		font-weight: 600;
	}
}

* {
	box-sizing: border-box;

	&::after,
	&::before {
		box-sizing: border-box;
	}

	&::selection {
		background: @primary-color;
		color: @white-color;
	}
}

html {
	padding: 0px;
	margin: 0px;
	scroll-behavior: auto;

	body {
		font-size: 14px;
		color: @default-color;
		font-family: @primary-font;
		font-weight: normal;
		margin: 0px;
		padding: 0px;
		width: 100%;
		line-height: normal;
	}
}

p {
	font-family: @primary-font;
	font-size: 14px;
	color: @default-color;
	margin-bottom: 20px;
}

h1 {
	font-family: @primary-font;
	font-size: 20px;
	color: @default-color;
	margin-bottom: 20px;
	font-weight: 600;
}

h2 {
	font-family: @primary-font;
	font-size: 20px;
	color: @default-color;
	margin-bottom: 20px;
	font-weight: 400;
}

h3 {
	font-family: @secondary-font;
	font-size: 24px;
	color: @default-color;
	margin-bottom: 20px;
	font-weight: 700;
}

h4 {
	font-family: @primary-font;
	font-size: 18px;
	color: @default-color;
	margin-bottom: 20px;
	font-weight: 600;
}

h5 {
	// font-family: @primary-font;
	// font-size: 16px;
	color: @grey-color;
	margin-bottom: 15px;
	font-weight: 600;
}

// h6 {
// }

.btnWrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5px;
}

button {
	&.ant-btn {
		background: #ffffff;
		box-shadow: 0px 4px 20px rgba(40, 43, 54, 0.08);
		border-radius: 8px;
		font-size: 12px;
		font-weight: 600;
		font-family: @primary-font;
		color: @primary-color;
		height: auto;
		padding: 7px 10px;
		border: none;
		height: 34px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		&:hover,
		&:focus {
			background-color: @primary-color;
			color: @white-color;
		}
	}

	&.btn-primary {
		background-color: @primary-color;
		color: @white-color;

		&:hover {
			background-color: rgba(74, 92, 251, 0.8);
			color: @white-color;
		}
	}

	&.btn-blue {
		background-color: @primary-color;
		color: @white-color;

		&:hover {
			background-color: rgba(74, 92, 251, 0.8);
			color: @white-color;
		}
	}

	&.btn-secondary {
		background-color: rgba(74, 92, 251, 0.1);
		color: @primary-color;
		font-size: 14px;
		padding: 14px 16px;
		height: auto;
		line-height: 1;

		&:hover {
			background-color: rgba(74, 92, 251, 0.2);
			color: @primary-color;
		}
	}
}

.ant-btn {
	&.btnLink {
		background-color: transparent;
		padding: 0;
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
		font-size: 14px;
		height: auto;
		line-height: 1;
		color: @primary-color;
		margin: 0;

		&:hover,
		&:focus {
			background-color: transparent;
			color: @primary-color;
		}

		svg {
			margin-left: 10px;
		}
	}
}

.ant-form {
	width: 100%;

	.ant-select-selector {
		background-color: #f2f3f7 !important;
	}

	.ant-form-item {
		position: relative;
		margin-bottom: 10px;

		.ant-form-item-label {
			padding: 0;
			position: absolute;
			left: 16px;
			top: 5px;
			z-index: 9;
			.transition;

			label {
				font-size: 12px;
				color: @grey-color;
				font-weight: 400;
				font-family: @primary-font;
				height: 100%;
			}
		}

		.ant-form-item-control-input {
			.ant-input {
				font-size: 14px;
				color: @default-color;
				font-weight: 600;
				font-family: @primary-font;
				height: 50px;
				border: none;
				display: flex;
				flex-wrap: wrap;
				padding: 20px 20px 5px 16px;
				align-items: center;
				background-color: @lighgrey-color;
				padding: 0 15px;
				.transition;
				border-radius: 12px;
			}
		}

		.ant-select-selector {
			height: 50px;
			font-size: 14px;
			color: @default-color;
			font-weight: 600;
			font-family: @primary-font;
			height: 50px;
			border: none;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			background-color: @lighgrey-color;
			padding: 0 15px;
			border-radius: 12px;

			.ant-select-selection-placeholder {
				font-size: 14px;
				color: @default-color;
				font-weight: 600;
				font-family: @primary-font;
				padding: 15px 0px 0px 0px;
			}

			// .ant-select-selection-item{
			// 	top: 5px;
			// }
		}

		.ant-picker {
			height: 50px;
			border: none;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			background-color: @lighgrey-color;
			// padding: 0 15px;
			.transition;
			border-radius: 12px;

			.ant-picker-input {
				input {
					font-size: 14px;
					color: @default-color;
					font-weight: 600;
					padding: 18px 20px 5px 5px !important;
					font-family: @primary-font;
				}
			}

			.ant-picker-suffix {
				color: @primary-color;
			}
		}

		textarea {
			min-height: 130px;
			resize: none;
			padding: 15px !important;
		}
	}

	.switchListing {
		border-top: 1px solid @altralight-color;

		.switchItem {
			// border-bottom: 1px solid @altralight-color;

			.switchTitle {
				padding: 10px 0;
				display: flex;

				flex-wrap: nowrap;
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				span {
					font-size: 14px;
					// font-family: @secondary-font;
					.semiMediumFont();
					color: @grey-color;
					width: 50%;
				}

				.iconSwitch {
					display: flex;
					align-items: center;

					svg {
						margin-right: 10px;
						.transition();
						cursor: pointer;

						&:hover {
							path {
								fill: rgba(74, 92, 251);
							}
						}
					}
				}

				.starIcon {
					display: flex;
					align-items: center;

					span {
						margin-left: 10px;
					}
				}
			}

			.switchItemWrap {
				.ant-form-item-label {
					width: 20% !important;
				}
			}

			.goalLine {
				border-bottom: 1px solid #e5e7ec;
			}
		}
	}
}

.ant-checkbox-wrapper {
	font-size: 14px;
	font-family: @secondary-font;
	color: @default-color;
	margin: 5px 0px;
	font-weight: 400;

	.ant-checkbox {
		.ant-checkbox-inner {
			border-color: @primary-color;
		}
	}
}

.ant-checkbox-group {
	.ant-checkbox-group-item {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 15px;
	}
}

.ant-switch {
	min-width: 50px;
	height: 30px;
	background-color: @altralighgrey-color;

	&.ant-switch-checked {
		background-color: @primary-color;

		.ant-switch-handle {
			left: calc(100% - 24px - 3px);
		}
	}

	.ant-switch-handle {
		top: 4px;
		width: 23px;
		height: 23px;
		left: 3px;

		&::before {
			border-radius: 50%;
			box-shadow: none;
		}
	}
}

.reminderMsg {
	position: relative;
	background-color: @lightOrange;
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	border-radius: 12px;

	>span {
		padding-left: 30px;
		font-size: 13px;
		color: @orange-color;
		font-weight: 500;
		font-family: @secondary-font;
		position: relative;
	}

	i {
		background-color: @orange-color;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;

		span {
			color: @white-color;
		}
	}
}

.infoMsg {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 12px;

	>span {
		padding-left: 25px;
		font-size: 16px;
		color: @grey-color;
		font-weight: 600;
		font-family: @primary-font;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	i {
		background-color: rgba(103, 106, 120, 0.4);
		width: 20px;
		height: 20px;
		border-radius: 50%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;

		span {
			color: @grey-color;
			font-size: 14px;
			font-weight: 400;
		}
	}
}

.selectWrap {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	margin-bottom: 20px;

	.ant-form-item {
		margin: 0;
		position: relative;
		min-width: 190px;
		background-color: @lighgrey-color;
		border-radius: 12px;
		min-height: 40px;
		display: block;
		padding: 0 12px;
	}

	.ant-select {
		.ant-select-selector {
			border: none;
			background-color: transparent;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			border: none !important;
			outline: none !important;
			box-shadow: none !important;
			padding-left: 0;
			height: 50px;

			.ant-select-selection-search-input {
				height: 100%;
			}

			.ant-select-selection-item,
			.ant-select-selection-placeholder {
				font-size: 14px;
				font-family: @secondary-font;
				font-weight: 500;
				color: @black-color;
			}
		}
	}

	.ant-select-focused {
		box-shadow: none;
	}
}

.dropdownWrap {
	display: flex;
	flex-wrap: wrap;
	margin: 0;

	.ant-form-item {
		padding: 0;
		margin: 0;
	}

	.ant-select {
		padding-top: 3px;

		.ant-select-selector {
			border: none;
			background-color: transparent;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			border: none !important;
			outline: none !important;
			box-shadow: none !important;
			padding-left: 0;

			.ant-select-selection-item,
			.ant-select-selection-placeholder {
				font-size: 14px;
				font-family: @secondary-font;
				font-weight: 500;
				color: @black-color;
				line-height: 1;
			}
		}
	}

	.ant-select-selection-item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		>div {
			width: 20px;
			height: 20px;
			border-radius: 50%;

			&.colorPink {
				background-color: @colorPink;
			}

			&.lightPinkColor {
				background-color: @lightPinkColor;
			}

			&.colorOrange {
				background-color: @colorOrange;
			}

			&.lightOrangeColor {
				background-color: @lightOrangeColor;
			}

			&.colorGreen {
				background-color: @colorGreen;
			}

			&.lightGreenColor {
				background-color: @lightGreenColor;
			}

			&.colorBlack {
				background-color: #000;
			}
		}
	}

	.ant-select-selection-item {
		top: 3px;
		left: 5px;
	}

	.ant-form-item-label {
		position: relative !important;
		left: 0px !important;

		label {
			color: @grey-color;
			font-family: "GT Walsheim Pro";
			font-weight: 500;
			font-size: 14px;
		}
	}
}

.editValue {
	.ant-modal-body {
		padding: 0px 24px 0px 24px;

		.ant-form-item {
			margin-bottom: 10px;
		}
	}

	.ant-modal-footer {
		border-top: none;
		padding: 10px 24px 24px 24px;
	}

	.btn-primary {
		width: 100%;
		height: 50px;

		span {
			.mediumFont();
			font-size: 14px;
		}

		&:hover {
			background-color: @primary-color;
		}
	}
}

.memberModel {
	max-width: 430px;
	margin: 0 auto;
	box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: @white-color;
	border-radius: 10px;
	font-family: @primary-font;

	h5 {
		color: #000;
	}

	.ant-modal-content {
		box-shadow: none;
		background-color: transparent;
		max-height: 770px;

		.ant-modal-header {
			padding: 24px 24px 0px 24px;
		}

		.ant-modal-body {
			padding-top: 20px;
			max-height: 620px;
			padding: 15px 24px 0px 24px;
			overflow: auto;

			.ant-divider-horizontal {
				margin: 24px 0px 10px 0px;
			}
		}

		.ant-modal-footer {
			border-top: none;
			padding: 0px 24px 0px 24px;

			.inviteBtn {
				width: 100%;
				height: 50px;
				background-color: @primary-color;
				color: @white;

				span {
					.mediumFont();
					font-size: 14px;
				}
			}
		}

		.ant-input {
			padding: 20px 20px 5px 16px !important;
		}
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
		border: none;
		padding: 24px 24px 0px 24px;

		.ant-modal-title {
			font-size: 16px;
			font-weight: 600;
			color: @default-color;
		}
	}

	.selectListing {
		.selectTitle {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			gap: 15px;
			margin-bottom: 15px;

			span {
				font-size: 14px;
				color: @grey-color;
				font-family: @primary-font;
			}

			.check {
				.ant-checkbox {
					.ant-checkbox-inner {
						border-color: @primary-color;
					}

					&.ant-checkbox-checked {
						.ant-checkbox-inner {
							background-color: @primary-color;
						}
					}
				}

				span {
					font-size: 14px;
					font-family: @secondary-font;
					color: @default-color;
				}
			}
		}

		.ant-collapse {
			border: none;
			background-color: transparent;

			.ant-collapse-item {
				border: none;

				.ant-collapse-header {
					background-color: transparent;
					// padding: 10px 0;
					padding: 5px 0px;

					.ant-collapse-header-text {
						font-size: 16px;
						font-weight: 500;
						font-family: @secondary-font;
						color: @grey-color;
						word-break: break-all;
						padding-right: 15px;
					}

					.ant-collapse-arrow {
						right: 0;
						font-size: 12px;
						color: @primary-color;
						font-weight: 700;
					}
				}
			}

			.ant-collapse-content {
				border: none;

				.ant-collapse-content-box {
					padding: 0;
					border-bottom: 1px solid @altralight-color;
					padding-bottom: 5px;
					margin-bottom: 5px;
				}
			}
		}

		.selectcheckboxList {
			width: 100%;

			.checkAll {
				margin-bottom: 15px;
			}

			.ant-checkbox-wrapper {
				word-break: break-all;
			}
		}
	}
}

.ant-form-item input::placeholder {
	color: @default-color;
	opacity: 1;
}

.ant-form-item input:-ms-input-placeholder {
	color: @default-color;
	opacity: 1;
}

.ant-form-item input::-ms-input-placeholder {
	color: @default-color;
	opacity: 1;
}

.searchInput input::placeholder {
	color: @grey-color;
	opacity: 1;
}

.searchInput input:-ms-input-placeholder {
	color: @grey-color;
	opacity: 1;
}

.searchInput input::-ms-input-placeholder {
	color: @grey-color;
	opacity: 1;
}

.btncenter {
	text-align: center;

	button {
		margin: 10px auto 20px;
	}
}

button {
	&.primaryBtn {
		font-size: 16px;
		font-weight: 600;
		color: @white-color;
		font-family: @primary-font;
		padding: 12px 25px;
		border-radius: 10px;
		background-color: @primary-color;
		height: auto;
		box-shadow: 0px 4px 20px rgba(40, 43, 54, 0.08);
		border: 2px solid transparent;
		.transition;

		&:hover,
		&:focus {
			background-color: @white-color;
			color: @primary-color;
			border-color: @primary-color;
		}
	}
}

.linkbtn {
	font-size: 16px;
	font-weight: 600;
	font-family: @primary-font;
	color: @grey-color;
	display: flex;
	justify-content: center;

	&:hover {
		color: @primary-color;
	}
}

.rightFirstTabWrap {
	display: flex;
	flex-wrap: wrap;
	// margin-bottom: 12px;
	padding: 8px 0px;
	margin-right: 24px;

	.modeWrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		background-color: @lighgrey-color;
		padding: 3px;
		border-radius: 12px;

		button {
			// padding: 10px 20px;
			padding: 7px 20px;
			background-color: transparent;
			font-size: 14px;
			font-family: @primary-font;
			color: @grey-color;
			height: auto;

			svg {
				margin-right: 10px;
			}

			border-radius: 10px;
			box-shadow: none;
			font-weight: 600;

			&.active {
				background-color: #fff;
				// color: @default-color;
				color: @primary-color;

				svg {
					path {
						fill: @primary-color;
					}
				}
			}

			&:hover {
				color: @primary-color;

				svg {
					path {
						fill: @primary-color;
					}
				}
			}
		}
	}

	@media screen and(max-width:1560px) {
		.modeWrap {
			button {
				padding: 7px 12px;
			}
		}
	}
}

.containerWrap {
	padding: 15px 5px 0px 0px;
	background-color: @body-bg;
}

.calendarWrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-left: 10px;
	// margin-right:24px;

	.ant-form-item {
		margin: 0;
		position: relative;
		// width: 130px;
		max-width: 300px;

		.ant-picker {
			height: 40px;
			border: none;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			background-color: @lighgrey-color;
			padding: 0 15px;
			box-shadow: none;
			outline: none;
			.transition;
			border-radius: 12px;

			.ant-picker-input {
				input {
					font-size: 14px;
					color: @default-color;
					font-weight: 600;
					font-family: @primary-font;
					padding: 0px 0px 0px 0px !important;
				}
			}

			.ant-picker-suffix {
				display: none;
			}

			.ant-picker-input {
				padding-left: 30px;
			}
		}

		.ant-form-item-label {
			position: absolute;
			left: 20px;
			top: 50%;
			// transform: translateY(-50%);
			transform: translateY(-42%);
			z-index: 9;

			label {
				&::after {
					display: none;
				}
			}
		}
	}

	.ant-picker-clear {
		svg {
			.transition();

			&:hover {
				fill: @primary-color;
			}
		}
	}
}

.tableView {
	margin: 30px 25px;
	padding: 0px 0px 10px 0px;
	border-radius: 20px;
	background-color: @white-color;
	// height: calc(90vh - 220px);
	height: 100%;

	.ant-table {
		border-radius: 20px;
		box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
	}

	.ant-table-wrapper {
		.ant-table-content {
			border-radius: 20px;

			.helpIcon {
				margin: 0px 0px 2px 5px;
			}
		}

		&.editTable {
			.ant-table-content {
				border-radius: 0px;
			}

			.ant-table {
				border-top: 1px solid rgba(186, 190, 203, 0.5);
				border-radius: 0;
			}
		}
	}

	.tableTitle {
		background-color: @white-color;
		padding: 15px;
		text-align: center;
		border-radius: 15px 15px 0 0;
		border-bottom: 1px solid @body-bg;

		h4 {
			font-size: 14px;
			font-family: @secondary-font;
			font-weight: 700;
			color: @default-color;
			margin: 0;
		}
	}

	.ant-table-content {
		// height: 68vh;
		height: 100%;
		overflow: auto !important;
		//    .ant-table-tbody{

		//    }
	}

	table {
		font-size: 12px;
		color: @black-color;
		font-family: @secondary-font;
		text-align: center;
		padding: 0;
		table-layout: fixed !important;

		.ant-table-thead {
			position: sticky;
			z-index: 999;
			top: 0;

			tr {
				th {
					text-align: center;
					background-color: #f6f6f9;
					border-bottom: none;
					font-family: @primary-font;
					font-weight: 700;
					font-size: 12px;
					color: @grey-color;
					padding: 5px 0px 5px 0px;

					&::before {
						display: none;
					}

					&.ant-table-cell-fix-left-last {
						// box-shadow:10px 0px 10px -3px rgba(0, 0, 0, 0.1);
						z-index: 99;
					}

					&.FirstCol {
						background-color: @white;
						box-shadow: 9px 2px 9px -1px rgba(61, 61, 61, 0.1);
						left: 50px !important;

						.checkbox {
							display: flex;
							align-items: center;
							justify-content: space-around;

							label {
								margin-right: 7px;
							}

							.checkTitle {
								font-size: 12px;
								.semiMediumFont();
								color: #000;
							}
						}
					}

					&.viewCol {
						background-color: @white;
						box-shadow: 9px 2px 9px -1px rgba(61, 61, 61, 0.1);
						left: 0px !important;

						.checkbox {
							display: flex;
							align-items: center;
							justify-content: space-around;

							label {
								margin-right: 7px;
							}

							.checkTitle {
								font-size: 12px;
								.semiMediumFont();
								color: #000;
							}
						}
					}

					&.dragCol {
						background-color: @white;
					}

					&.paddingRow {
						padding: 0px 0px 0px 0px !important;
						border-right: 1px solid rgba(186, 190, 203, 0.5);
					}

					&.borderRight {
						border-right: 1px solid rgba(186, 190, 203, 0.5);
					}
				}
			}

			&::after {
				position: absolute;
				content: "";
				background-color: rgba(186, 190, 203, 0.5);
				width: 100%;
				height: 1px;
				bottom: 0px;
				left: 0px;
				z-index: 99;
			}
		}

		.ant-table-tbody {
			tr {
				height: 40px;
				margin-bottom: 10px;
				position: relative;

				td {
					border-bottom: 10px solid @white;

					position: relative;
					// border-top: 5px solid @white-color;
					// border-bottom: 5px solid @white-color;
					padding: 7px 10px;

					&:first-child {
						text-align: left;
						// border-radius: 20px 0 0 20px;
						box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);

						&::after {
							background-color: transparent;
						}
					}

					&.paddingRow {
						&::after {
							height: 55px;
							top: 0px;
							background-color: rgba(186, 190, 203, 0.4);
						}
					}

					// &:nth-child(2) {
					// 	&::after {
					// 		background-color: transparent;
					// 	}
					// }

					&.borderRight {
						border-right: 1px solid #babecb;

						&::after {
							background-color: transparent;
						}
					}

					&.dragCol {
						background-color: @white;
						z-index: 999;

						&::after {
							background-color: transparent;
						}
					}

					&::after {
						position: absolute;
						content: "";
						background-color: #babecb;
						height: 50%;
						width: 1px;
						right: 0;
						top: 32%;
					}

					&.ant-table-cell-fix-left-last {
						// box-shadow: 10px 0px 10px -3px rgba(0, 0, 0, 0.1);
						z-index: 99;
					}

					.whiteBackground {
						background-color: #fff;
					}

					&.colorPink {
						td {
							background-color: @lightPink-color;
						}
					}

					&.colorGreen {
						background-color: @lightgreen-color;
					}

					&.colorOrange {
						background-color: @lightOrangeColor;
					}

					&.redColor {
						background-color: @lightPink-color;
					}

					.userInfoWrap {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-between;
						// justify-content: center;

						.thumb {
							display: flex;
							flex-wrap: wrap;
							align-items: center;

							img {
								max-width: 26px;
								border-radius: 50%;
							}

							span {
								// padding-left: 10px;
								font-size: 12px;
								font-weight: 400;
								font-family: "GT Walsheim Pro";
								color: @black-color;
							}
						}

						.icon {
							display: inline-flex;
						}
					}

					.userMetricWrap {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						// text-align: left;
						// justify-content: center;

						.thumb {
							display: flex;
							flex-wrap: nowrap;
							align-items: center;
							width: 75%;
							text-align: left;

							img {
								// max-width: 26px;
								border-radius: 50%;
							}

							span {
								// padding-left: 10px;
								font-size: 12px;
								font-weight: 400;
								font-family: @secondary-font;
								color: @black-color;
								width: calc(100% - 50px);
							}

							.ant-avatar {
								.ant-avatar-string {
									color: @white-color;
								}
							}

							.tableHeader {
								text-align: center;
								color: #000000;
								width: 70%;

								div {
									max-width: 300px;
									word-break: break-word;
									text-align: center;
									position: relative;
									left: 40px;
									// 		&:before {
									// 	height: 1px;
									// 	width: 60px;
									// 	content: "";
									// 	left: 22px;
									// 	top: 23px;
									// 	position: absolute;
									// 	background-color: #babecb
									// }

									// &:after {
									// 	height: 1px;
									// 	width: 60px;
									// 	content: "";
									// 	top: 23px;
									// 	right: 15%;
									// 	position: absolute;
									// 	background-color: #babecb;
									// }
									b {
										color: #000;
									}

									@media screen and (max-width:1582px) {
										max-width: 250px;
									}

									@media screen and (max-width:1480px) {
										max-width: 200px;
									}

									@media screen and (max-width:1380px) {
										max-width: 130px;
										font-size: 11px;
									}

									@media screen and (max-width:1260px) {
										max-width: 100px;
									}
								}


								.headerNone {
									left: 40px;

									@media screen and(max-width:1200px) {
										left: 40px;
									}

									&::before {
										display: none;
									}
								}

								.leftLine {
									background-color: #babecb;
									height: 1px;
									position: absolute;
									left: 30px;
									width: 50px;
									top: 50%;
								}

								.rightLine {
									background-color: #babecb;
									height: 1px;
									position: absolute;
									right: 70px;
									width: 50px;
									top: 50%;
								}

							}

						}

						.icon {
							display: flex;
							flex-wrap: nowrap;
							align-items: baseline;
							justify-content: flex-end;
							width: 25%;
						}
					}
					.tableMetricRow{
						position: relative;
						bottom:10px;
						.headerTitle{
							position: relative;
							margin-bottom:-20px;
							span{
								position: absolute;
								top:-24px;
								margin:0 auto;
								left:0;
								right:0;
								z-index:99;
	
							}
						}

					}
				

					.userHeader {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						// text-align: left;
						// justify-content: center;

						.thumb {
							// display: flex;
							// flex-wrap: nowrap;
							// align-items: center;
							width: 100%;
						

							.tableHeader {
								text-align: center;
								color: #000000;
								width: 100%;

								div {
									max-width: 300px;
									word-break: break-word;
									text-align: center;
									position: relative;
									left: 40px;

									b {
										color: #000;
									}

									@media screen and (max-width:1582px) {
										max-width: 250px;
									}

									@media screen and (max-width:1480px) {
										max-width: 200px;
									}

									@media screen and (max-width:1380px) {
										max-width: 130px;
										font-size: 11px;
									}

									@media screen and (max-width:1260px) {
										max-width: 100px;
									}
								}


								.headerNone {
									left: 40px;

									@media screen and(max-width:1200px) {
										left: 40px;
									}

									&::before {
										display: none;
									}
								}

								.leftLine {
									background-color: #babecb;
									height: 1px;
									position: absolute;
									left: 20px;
									width: 50px;
									top: 50%;
								}

								.rightLine {
									background-color: #babecb;
									height: 1px;
									position: absolute;
									right: 20px;
									width: 50px;
									top: 50%;
								}

							}

						}

					}

					.userBtnWrap {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						// justify-content: center;
						// justify-content: space-between;
						// width: 90px;

						.icon {
							display: inline-flex;
						}

						button {
							background-color: transparent;
							box-shadow: none;
							padding: 0px 8px 0px 0px;
						}
					}

					.dragIcon {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;
						min-width: 32px;
						cursor: pointer;

						button {
							background-color: transparent;
							box-shadow: none;

							svg {
								.transition();

								&:hover {
									path {
										fill: #d88080;
									}
								}
							}
						}
					}

					.tableResult {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-wrap: nowrap;

						svg {
							margin-left: 10px;
						}
					}
				}

				.ant-table-cell-row-hover {
					background-color: #ededed;
				}

				.ant-table-cell {
					background-color: #f0f1f3;

					&.goalRec {
						display: flex;
						align-items: center;
					}
				}

				// &::after{
				// 	position: absolute;
				// 	content: "";
				// 	background-color:@white;
				// 	height: 10px;
				// 	width:100%;
				// 	top:0;
				// 	left:0;
				// 	z-index:9;
				// }
			}

			.colorGreen {
				// background-color: transparent !important;

				td {
					background-color: @lightgreen-color;
				}

				.ant-table-cell-row-hover {
					background-color: #d7f5e3;
				}
			}

			.colorPink {
				// background-color: transparent !important;

				td {
					background-color: @lightPink-color;
				}

				.ant-table-cell-row-hover {
					background-color: #f7dede;
				}
			}

			.colorOrange {
				// background-color: transparent !important;

				td {
					background-color: #f5f1e0;
				}

				.ant-table-cell-row-hover {
					background-color: #fff4c4;
				}
			}

			.boldResult {
				font-weight: 700;
				color: @default-color;
				font-size: 12px;
			}

			.deleteIcon {
				display: flex;
				align-items: center;
				justify-content: flex-end !important;

			}
		}

		// .ant-table-cell{
		// 	@media screen and(max-width:1560px) {
		// 		&:nth-child(2){
		// 		  width:350px !important;
		// 		}
		// 		&:nth-child(3){
		// 		  width:200px !important;
		// 		}
		// 		&:nth-child(4){
		// 		  width:200px !important;
		// 		}
		// 		&:nth-child(5){
		// 		  width:200px !important;
		// 		}
		// 		&:nth-child(6){
		// 		  width:200px !important;
		// 		}

		// 	  }

		// }
		.ant-table-cell {
			&.colorPink {
				background-color: #fdcfcf6b !important;
			}
		}

		.rowHeaderBg {
			.ant-table-cell {
				// background-color: #e9e9e9 !important;
				background-color: #efefef59 !important;
			}
		}
	}

	.ant-table-row-expand-icon {
		display: none;
	}

	.ant-table-row-expand-icon-collapsed {
		display: none;
	}

	.ant-select-focused {
		box-shadow: none;
	}

	.resultError {
		width: 130px;

		.ant-form-item-explain {
			.ant-form-item-explain-error {
				text-align: left;
			}
		}

		.ant-input {
			padding: 0px 20px 0px 16px !important;
		}
	}

	.featureHeading {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		// border-bottom: 1px solid @greyborder-color;
		// padding: 12px 12px;
		margin-left: -10px;
		width: calc(100% + 20px);

		.featureHeadingWrap {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 30px;
			// padding: 10px 16px;

			button {
				margin: 10px 16px;

				&.btn-primary {
					background-color: rgba(74, 92, 251, 0.1);
					padding: 8px 40px;
					font-size: 14px;
					font-weight: 600;
					font-family: @primary-font;
					color: @primary-color;
					height: auto;

					&:hover,
					&:focus {
						background-color: @primary-color;
						color: @white-color;
					}
				}
			}

			.featureCheck {
				margin: 0px 35px 0px 0px;

				span {
					font-size: 14px;
					color: @default-color;
					font-family: @primary-font;
					margin-right: 10px;
				}
			}
		}
	}

	.backgroundWhite {
		background-color: #000;
	}

	@media screen and(max-width:1560px) {
		.featureHeading {
			.featureHeadingWrap {
				gap: 20px;

				button {
					&.btn-primary {
						padding: 8px 25px;
					}
				}
			}
		}
	}

	.ant-avatar {
		background-color: @primary-color;
	}

	.editTable {
		.ant-table-container {
			.ant-table-content {
				table {
					.ant-table-tbody {
						.ant-table-measure-row {
							display: none;
						}

						.ant-table-row {
							td {
								&:nth-child(3) {

									// box-shadow: 9px 2px 9px -1px rgba(61, 61, 61, 0.1);
									&::after {
										display: none;
									}
								}

								border-bottom: 10px solid @white;

								&.FirstCol {
									box-shadow: 9px 2px 9px -1px rgba(61, 61, 61, 0.1);
									left: 50px !important;

									&:after {
										display: none;
									}
								}
							}

							.dragCol {
								background-color: @white !important;
								z-index: 999;
							}
						}
					}
				}
			}
		}
	}

	.metricDrawer {
		.footerIcons {
			display: flex;
			align-items: center;
			justify-content: space-evenly;

			.deleteFooter {
				span {
					display: flex;
					align-items: center;

					svg {
						margin-right: 10px;
					}

					&:hover {
						color: @colorPink;

						svg {
							path {
								fill: @colorPink;
							}
						}
					}
				}
			}

			.archiveFooter {
				&:hover {
					color: @primary-color;

					svg {
						path {
							fill: @primary-color;
						}
					}
				}
			}
		}
	}
}

.commentPopover {
	.ant-popover-inner {
		max-width: 250px !important;
	}

	.ant-popover-inner-content {
		width: 100%;
	}

	.colorDropDown {
		.ant-select-selector {
			background-color: transparent !important;
			border-radius: 50%;
			height: 24px;
		}
	}

	.assignItem {

		// box-shadow: 0px 4px 20px rgb(40 43 54 / 8%);
		// padding: 10px 16px;
		// display: flex;
		// margin-bottom: 15px;
		// border-radius: 12px;
		// align-items: center;
		// justify-content: space-between;
		// background-color: @white;
		span {
			font-size: 12px;
			color: @grey-color;
			font-weight: 400;
			font-family: @primary-font;
		}

		.dropdownWrap {
			box-shadow: 0px 4px 20px rgb(40 43 54 / 8%);
			padding: 10px 16px;
			display: flex;
			margin-bottom: 15px;
			border-radius: 12px;
			align-items: center;
			justify-content: space-between;
			background-color: @white;

			.ant-select {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				padding: 0px;

				&.ant-select-single {
					width: 30px;

					.ant-select-selector {
						background-color: transparent !important;
						height: 20px;
						padding: 0px 6px;

						.ant-select-selection-item {
							position: absolute;
							top: 2px;
							left: 2px;
						}
					}
				}

				&.ant-select-focused {
					box-shadow: none !important;
				}
			}
		}

	}

	.ant-form-item {
		.ant-form-item-label {
			padding: 5px 0px 0px 0px !important;
			top: 0px !important;
		}

		.ant-input {
			padding: 20px 20px 5px 16px !important;
		}

		.ant-select-selector {
			.ant-select-selection-item {
				top: 9px;
				font-weight: 600;
				color: #000;
			}
		}
	}

	.item {
		.dropdownWrap {
			box-shadow: 0px 4px 20px rgb(40 43 54 / 8%);
			padding: 3px 10px;
			display: flex;
			margin-bottom: 15px;
			border-radius: 12px;
			align-items: center;
			justify-content: space-between;

			.ant-select-focused {
				box-shadow: none;
				border: none;
			}

			.ant-select-selector {
				.ant-select-selection-item {
					padding-bottom: 4px;
				}
			}
		}

		.textBoxArea {
			textarea {
				border-radius: 12px;
			}
		}
	}

	.ant-form-item-explain {

		width: 220px;
		padding: 5px 0px;
	}
}

.editValue {
	.ant-modal-header {
		padding: 24px 24px 10px 24px;
	}

	.ant-input {
		padding: 20px 20px 5px 16px !important;
	}
}

.graphView {
	margin-bottom: 30px;
	padding: 0px;
	border-radius: 20px;
	background-color: @white-color;

	.tableTitle {
		background-color: @white-color;
		padding: 15px;
		text-align: center;
		border-radius: 15px 15px 0 0;
		border-bottom: 1px solid @body-bg;

		h4 {
			font-size: 14px;
			font-family: @secondary-font;
			font-weight: 700;
			color: @default-color;
			margin: 0;
		}

		.titleWrap {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			gap: 12px;

			img {
				max-width: 30px;
			}

			.ant-avatar {
				background-color: @primary-color;

				.ant-avatar-string {
					font-size: 16px;
				}
			}
		}
	}

	canvas {
		padding: 15px;
	}
}

.ant-popover {
	&.ant-popover-placement-bottom {
		.ant-popover-inner {
			padding: 20px;
			box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			min-width: 420px;

			.ant-popover-title {
				font-size: 16px;
				font-weight: 600;
				font-family: @primary-font;
				color: @default-color;
				padding: 0;
				border-bottom: none;
			}

			.ant-popover-inner-content {
				padding: 0;

				.viewersListing {
					overflow: auto !important;
					height: 80vh;
				}
			}
		}
	}

	.viewersListing {
		.itemList {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			font-family: @secondary-font;
			font-weight: 400;
			font-size: 14px;
			padding: 0;
			background-color: @white-color;
			border-radius: 8px;
			.transition;
			margin: 10px 0 18px;

			.thumb {
				width: 42px;

				img {
					max-width: 100%;
					border-radius: 50%;
					border: 2px solid @white;
				}
			}

			.content {
				width: calc(100% - 110px);
			}

			h6 {
				font-size: 14px;
				color: @default-color;
				font-weight: 400;
				margin: 0;
				line-height: 1.3;
				font-family: @primary-font;
			}

			span {
				font-size: 14px;
				color: @grey-color;
				font-weight: 400;
				font-family: @primary-font;
			}
		}

		.btncenter {
			Button {
				width: 100%;
			}
		}
	}

	.ant-btn {
		height: 50px;
		background-color: @primary-color;
		color: #fff;
		width: 100%;
		font-size: 16px;
		.mediumFont();
	}

	.popCloseBtn {
		margin-top: 10px;
	}
}

.accessModal {
	.ant-modal-content {
		// overflow: auto;
		height: 550px;
		width: 420px !important;

		.ant-modal-header {
			padding: 24px 24px 10px 24px;
		}

		.ant-modal-body {
			overflow: auto;
			height: 350px;
			padding: 0px 24px 0px 24px;
		}

		.ant-modal-footer {
			border: none;
		}
	}

	.viewersListing {
		.itemList {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			font-family: @secondary-font;
			font-weight: 400;
			font-size: 14px;
			padding: 0;
			background-color: @white-color;
			border-radius: 8px;
			.transition;
			margin: 10px 0 18px;

			.thumb {
				width: 42px;

				img {
					max-width: 100%;
					border-radius: 50%;
					border: 2px solid @white;
				}
			}

			.thumb.active {
				opacity: 0.5;
			}

			.content.active {
				opacity: 0.5;
			}

			.content {
				width: calc(100% - 110px);
			}

			h6 {
				font-size: 14px;
				color: @default-color;
				font-weight: 400;
				margin: 0;
				line-height: 1.3;
				font-family: @primary-font;
			}

			span {
				font-size: 14px;
				color: @grey-color;
				font-weight: 400;
				font-family: @primary-font;
			}
		}

		.btncenter {
			Button {
				width: 100%;
			}

			button {
				&:hover {
					background-color: @primary-color;
				}
			}
		}

		.ant-switch-disabled {
			opacity: 1;
		}
	}

	.ant-btn {
		height: 50px;
		background-color: @primary-color;
		color: #fff;
		width: 100%;
		font-size: 16px;
		.mediumFont();
	}

	.popCloseBtn {
		margin-top: 10px;
	}

	.ant-modal-footer {
		padding: 10px 16px 20px 16px;
	}
}

.back {
	margin-bottom: 30px;

	a {
		font-size: 20px;
		color: @default-color;
		font-family: @secondary-font;
		line-height: 1.3;
		position: relative;
		font-weight: 500;
		padding-left: 35px;
		display: inline-block;

		i {
			width: 24px;
			height: 24px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			background-color: rgba(74, 92, 251, 0.1);
			border-radius: 50%;
			.transition;
			position: absolute;
			left: 0;
			top: 0;

			svg {
				margin: 0 auto;

				path {
					.transition;
				}
			}
		}

		&:hover {
			// color: @primary-color;

			i {
				background-color: @primary-color;

				svg {
					path {
						stroke: @white-color;
					}
				}
			}
		}
	}
}

.ant-picker-content {
	tr {
		td {
			font-size: 13px;
			font-weight: 400;
			font-family: @primary-font;
		}
	}
}

.ant-picker-footer {
	.ant-picker-today-btn {
		display: none;
	}

	.ant-picker-footer-extra {
		.BtnWrap {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			padding: 10px;

			.ant-btn {
				width: calc(50% - 5px);
				margin: 5px 0;
				font-size: 14px;
				font-weight: 600;
				padding: 10px 15px;
				height: auto;

				&.ant-btn-default {
					background-color: @lighgrey-color;
					color: @grey-color;

					&:hover {
						background-color: @primary-color;
						color: @white-color;
					}
				}

				&.btn-primary {
					background-color: @primary-color;
					color: @white-color;

					&:hover {
						background-color: rgba(74, 92, 251, 0.8);
						color: @white-color;
					}
				}
			}
		}

		&:not(:last-child) {
			border-bottom: none;
		}
	}
}

.ant-picker-dropdown {
	.ant-picker-panel-container {
		padding: 0px 0px 60px 0px;
		border-radius: 20px;

		.ant-picker-panel {
			display: flex;
			align-items: center;
			flex-direction: column-reverse;

			.ant-picker-footer {
				border-top: none;
				background-color: #fdf5ee;
			}

			.ant-picker-footer-extra {
				.checkHeader {
					margin: 8px 0px 0px 0px;

					span {
						font-size: 13px;
						font-family: @primary-font;
					}
				}

				.BtnWrap {
					display: flex;
					flex-wrap: wrap;
					gap: 10px;
					position: absolute;
					padding: 0px;
					bottom: 5px;
					left: 1px;
					width: 100%;
					align-items: center;
					justify-content: center;

					.ant-btn {
						width: calc(50% - 25px);
						margin: 5px 0;
						font-size: 14px;
						font-weight: 600;
						padding: 10px 8px;
						height: auto;

						&.ant-btn-default {
							background-color: @lighgrey-color;
							color: @grey-color;

							&:hover {
								background-color: @primary-color;
								color: @white-color;
							}
						}

						&.bgBlue {
							background-color: @primary-color;
							color: @white-color;

							&:hover {
								background-color: rgba(74, 92, 251, 0.8);
								color: @white-color;
							}
						}
					}
				}
			}
		}
	}
}

// --------------------------------------------------------comman ag-grid css starts from here------------------------------------------------------------------
.ag-grid-height {
	height: calc(100vh - 210px) !important;
	margin-top: 30px;
}

.ag-grid-wrapper {
	// padding: 0px 30px 0px 0px;

	.ag-root-wrapper {
		border: none;
		box-shadow: 0px 4px 40px 4px rgb(40 43 54 /0.08);
		border-radius: 2px;
	}
}

.ag-header {
	background-color: #fff !important;
	border-bottom: none !important;

	.ag-header-cell-resize {
		&:after {
			display: none;
		}
	}

	.ag-header-cell-text {
		font-family: @primary-font;
		font-weight: 400;
		font-size: 14px;
		color: grey;
	}

	.ag-header-cell {
		border-top-color: #e5e7ec !important;
	}
}

.ag-body-viewport {
	box-shadow: 0px 4px 40px rgb(40 43 54 / 8%);
}

.ag-cell {

	// line-height: 50px;
	.gridButton {
		height: 50px;
		display: flex;
		align-items: center;

		margin-left: -5px;

		.ant-btn {
			background-color: transparent;
			box-shadow: none;
		}

		.deleteBtn {
			svg {
				.transition();

				&:hover path {
					fill: #d88080;
				}
			}
		}
	}
}

.ag-header {
	background-color: @white;
	border-bottom: none;

	.ag-header-cell-resize {
		&:after {
			display: none;
		}
	}

	.ag-header-cell-text {
		.semiMediumFont();
		font-size: 14px;
		color: @grey-color;
	}

	.ag-header-cell {
		border-top-color: @altralight-color !important;
	}

	.ag-header-container {
		padding: 0px 25px;
	}
}

.ag-center-cols-viewport {
	padding: 0px 25px;
}

.ag-cell {
	.semiMediumFont();
	font-size: 14px;
}

.ag-cell-range-selected {
	border-color: @primary-color;
}

.ag-paging-panel {
	justify-content: center;
	border: none;

	@media screen and (max-width: 600px) {
		display: block;
	}

	.ag-paging-row-summary-panel,
	.ag-paging-page-summary-panel {
		@media screen and (max-width: 600px) {
			margin: 2px 20px;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
		}
	}
}

.ag-body-viewport {
	box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
}

// .ag-center-cols-viewport {
// 	padding: 0px 25px;
// }
// --------------------------------------------------------comman ag-grid css ends from here------------------------------------------------------------------

// ---------------------------------------------profile css starts from here-------------------------------------------------------------------

.profilePasswordField {
	.ant-col-6 {
		padding-left: 0px !important;
	}

	// .ant-form-item-control-input-content {
	// 	padding: 0px 2px;

	// 	input:focus~span.ant-input-group-addon,
	// 	input:not(:placeholder-shown)~span.ant-input-group-addon {
	// 		transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
	// 		top: 25px;
	// 		font-size: 12px;
	// 	}

	// 	span.ant-input-group-addon {
	// 		&:focus {
	// 			transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
	// 			top: 25px;
	// 			font-size: 12px;
	// 		}
	// 	}

	// -----------------------------------------------------------------------------------------------------------------------------------------------------
	// -------------------------------------------------password label floating-----------------------------------------------------------------------------
	// 	.ant-input-password-large.ant-input-group-wrapper-status-success .ant-input-group-addon,
	// 	.ant-input-password-large.ant-input-group-wrapper-status-error .ant-input-group-addon {
	// 		transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
	// 		top: 25px;
	// 		font-size: 12px;
	// 	}
	// }
}

.tabFormWrap {
	.ant-btn {
		&:hover {
			background-color: #001aff1a;
		}
	}

	.ant-form-item {
		.ant-input {
			&:focus {
				box-shadow: 0 0 0 1px @primary-color;
			}
		}

		.ant-picker-focused {
			box-shadow: 0 0 0 1px @primary-color;
			border-color: @primary-color;
		}
	}

	.ant-select-selection-item {
		top: 9px;
		font-weight: 600;
	}
}

.metricDrawer {
	width: 100%;
	height: 76vh;
	overflow: auto;

	.ant-form-item {
		.ant-input {
			&:focus {
				box-shadow: 0 0 0 1px @primary-color;
			}
		}
	}

	.ant-select-focused {
		// border: 1px solid @primary-color;
		border-radius: 12px;
	}

	.listCategory {
		&::placeholder {
			color: #676a78 !important;
		}
	}

	.ant-segmented {
		border-radius: 12px;
		background: @lighgrey-color;
		width: 100%;

		margin-bottom: 14px;

		.ant-segmented-group {
			width: 100%;
			padding: 2px;
			height: 40px;

			.ant-segmented-item {
				padding: 4px 0px;
				width: 50%;
			}

			.ant-segmented-item-selected {
				border-radius: 10px;

				.ant-segmented-item-label {
					color: @primary-color;
				}
			}
		}

		.ant-segmented-item-label {
			.mediumFont();
			font-size: 14px;
			// color: @font-color;
		}
	}

	.listCategory {
		::placeholder {
			color: @lighgrey-color !important;
		}

		.ant-input {
			&:focus {
				box-shadow: none;
				border-bottom: 1px solid @primary-color !important;
				border-left: 1px solid @primary-color !important;
				border-top: 1px solid @primary-color !important;
				// box-shadow: 0 0 0 1px #4a5cfb;
			}

			&:focus~span.ant-input-group-addon {
				border: 1px solid @primary-color;
			}
		}
	}

	.ant-input {

		// &:focus{
		// 	box-shadow:none;
		// 	border-bottom:1px solid @primary-color !important;
		// 	border-left:1px solid @primary-color !important;
		// 	border-top:1px solid @primary-color !important;
		// 	// box-shadow: 0 0 0 1px #4a5cfb;
		// }
		&:focus~span.ant-input-group-addon {
			border: 1px solid @primary-color;
		}
	}

	// .ant-input-group-addon{
	// 	&:focus{
	// 		box-shadow:none;
	// 		border-bottom:1px solid @primary-color !important;
	// 		border-left:1px solid @primary-color !important;
	// 		border-top:1px solid @primary-color !important;
	// 	}
	// }
	.ant-input {
		padding: 20px 40px 5px 16px !important;
	}

	.searchName {
		.ant-input {
			padding: 10px 16px 10px 16px !important;
		}
	}

	.TeamAddarea {
		.ant-input {
			padding: 25px 20px 5px 16px !important;
		}
	}

	.dropdownWrap {
		.ant-form-item-label {
			left: 15px !important;
			top: 10px !important;
		}
	}

	.editMetricText {
		.ant-form-item-label {
			background: #f2f3f7;
			width: 90%;
			padding: 5px 0px 0px 0px;
			top: 0px !important;
		}

		textarea {
			padding: 23px 16px 5px 16px !important;

		}
	}

	.ant-form-item {
		.ant-form-item-label {
			padding: 5px 0px 0px 0px !important;
			top: 0px !important;
		}

		.ant-select-selector {
			.ant-select-selection-item {
				top: 9px;
				font-weight: 600;
				color: #000;
			}
		}
	}

	@media screen and(max-width:1560px) {
		height: 75vh;
	}

	@media screen and(max-width:1200px) {
		height: 69vh;
	}

	.recurringTab {
		margin-top: 14px;
	}

	.drawerOptions {
		background-color: @lighgrey-color;
		height: 50px;
		width: 100%;
		display: flex;

		align-items: center;
		justify-content: space-between;
		border-radius: 12px;
		margin-bottom: 10px;
		cursor: pointer;
		font-family: @primary-font;
		font-weight: 600;
		font-size: 14px;
		color: #000;

		.ant-select-selector {
			border: none;
			height: 45px;

			.ant-select-selection-item {
				line-height: 1.5;
			}
		}

		&.ant-select-focused {
			.ant-select-selector {
				box-shadow: none !important;
				border-color: transparent;
				border: none;
				border-radius: 12px;
				padding: 0px 16px;
			}
		}
	}

	.goalRangeDate {
		height: 50px;
		border: none;
		background-color: @lighgrey-color;
		margin-bottom: 16px;
		width: 100%;
		border-radius: 12px;
		margin-top: 5px;
	}

	.goalRangeTitle {
		margin-left: 10px;
		font-size: 12px;
		color: #676a78;
		font-weight: 400;
		font-family: @primary-font;
	}

	// .drawerOptions {
	// 	&.ant-collapse {
	// 		margin-bottom: 10px;
	// 		border: none;
	// 		background-color: @white;

	// 		.ant-collapse-item {
	// 			border: none;
	// 		}

	// 		.ant-collapse-header {
	// 			font-size: 12px;
	// 			color: @grey-color;
	// 			.semiMediumFont();
	// 			padding: 18px 16px;
	// 			line-height: 1.4;
	// 			height: 50px;
	// 			background-color: @lighgrey-color;
	// 			border-radius: 12px;
	// 			border: none;

	// 			.ant-collapse-expand-icon {
	// 				svg {
	// 					font-size: 14px;

	// 					path {
	// 						fill: @primary-color;
	// 					}
	// 				}
	// 			}
	// 		}

	// 		.ant-collapse-content {
	// 			border: none;
	// 			background-color: @white;
	// 			margin: 8px 0px 0px 0px;
	// 			box-shadow: 0px 4px 20px rgb(40 43 54 / 8%);
	// 			border-radius: 10px;

	// 			.ant-collapse-content-box {
	// 				padding: 0;
	// 			}

	// 			.submenuList {
	// 				margin: 7px 5px;
	// 				padding: 0;

	// 				li {
	// 					list-style: none;
	// 					padding: 0px 16px;
	// 					display: inline-block;
	// 					width: 100%;
	// 					border-radius: 0px;
	// 					display: flex;
	// 					align-items: center;
	// 					justify-content: space-between;
	// 					.transition();

	// 					a {
	// 						position: relative;
	// 						font-size: 14px;
	// 						font-family: @primary-font;
	// 						color: @default-color;
	// 						font-weight: 600;
	// 						margin: 10px 0px;
	// 					}

	// 					&:hover {
	// 						background-color: rgba(74, 92, 251, 0.05);
	// 						border-radius: 10px;
	// 					}

	// 				}
	// 			}
	// 		}
	// 	}
	// }
}

.goalDrawer {
	.nodeDropdownList {
		.item {
			display: flex;
			align-items: center;

			.dropdownWrap {
				.ant-form-item-label {
					left: 15px !important;
					top: 10px !important;
				}
			}
		}

		.textDecoration {
			width: 100%;
			padding: 5px 0px 10px 0px;

			.goalText {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0px 15px;
			}

			.ant-divider {
				margin: 10px 0px;
			}
		}
	}

	.ant-drawer-footer {
		.deleteFooter {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			.transition();

			span {
				display: flex;
				font-family: @primary-font;
				font-size: 14px;
				font-weight: 600;

				svg {
					margin-right: 5px;
				}
			}

			&:hover {
				color: #d88080;

				svg {
					path {
						fill: #d88080;
					}
				}
			}
		}
	}
}

.goalDropDown {
	.ant-dropdown-menu {
		padding: 5px 10px;

		.ant-dropdown-menu-item {
			padding: 10px 12px;

			.ant-dropdown-menu-item-icon {
				position: absolute;
				right: 0;
			}

			.ant-dropdown-menu-title-content {
				font-size: 14px;
				font-family: @primary-font;
				font-weight: 600;
			}
		}
	}
}

.teamDrawer {
	.ant-drawer-footer {
		.deleteFooter {
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			.transition();

			span {
				display: flex;
				font-family: @primary-font;
				font-size: 14px;
				font-weight: 600;

				svg {
					margin-right: 5px;
				}
			}

			&:hover {
				color: #d88080;

				svg {
					path {
						fill: #d88080;
					}
				}
			}
		}
	}
}

.nodeInfoDropdown {
	width: 100%;

	.nodeDropdownList {
		background-color: @white-color;
		box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
		border-radius: 14px;
		// padding: 10px 5px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 20px;

		.item {
			position: relative;
			width: 50%;

			&::after {
				content: "";
				position: absolute;
				right: 0;
				top: 0;
				width: 1px;
				height: 100%;
				background-color: @altralight-color;
			}

			.dropdownWrap {
				width: 100%;

				// align-items: center;
				.ant-form-item {
					width: calc(100% - 70px);

					.ant-form-item-label {
						left: 0;
					}
				}

				.ant-select-focused {
					box-shadow: none;
				}

				.ant-form-item-control-input {
					height: 25px;
					min-height: 25px;
				}

				.ant-form-item-label {
					top: 12px;
				}

				.ant-select-arrow {
					right: 5px;
				}
			}

			&:last-child {
				&::after {
					display: none;
				}

				.dropdownWrap {
					margin-right: 0;
				}
			}

			.selectWrap {
				.ant-form-item {
					min-height: auto;
					background-color: transparent;
					min-width: auto;

					.ant-form-item-label {
						label {
							font-weight: 500;
							color: @grey-color;
							font-family: @secondary-font;
						}
					}

					.ant-select-arrow {
						svg {
							color: @primary-color;
							font-weight: 700;
						}
					}
				}
			}

			&::placeholder {
				color: @grey-color;
			}
		}

		.ant-select-selector {
			background-color: transparent !important;
		}
	}
}

.ant-select-dropdown {

	// width: auto !important;
	// width:31px !important;
	// min-width:31px !important;
	.ant-select-item {
		font-size: 14px;
		font-weight: 400;
		color: @text-color;
		font-family: @primary-font;
		background-color: transparent !important;
		padding: 5px 5px;

		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&.ant-select-item-option-selected {
			font-weight: 700;
			color: @black-color;
		}

		.ant-select-item-option-content {
			>div {
				width: 20px;
				height: 20px;
				border-radius: 50%;

				&.colorPink {
					background-color: @colorPink;
				}

				&.lightPinkColor {
					background-color: @lightPinkColor;
				}

				&.colorOrange {
					background-color: @colorOrange;
				}

				&.lightOrangeColor {
					background-color: @lightOrangeColor;
				}

				&.colorGreen {
					background-color: @colorGreen;
				}

				&.lightGreenColor {
					background-color: @lightGreenColor;
				}

				&.colorBlack {
					background-color: #000;
				}

				&.noColor {
					background-color: transparent;
				}
			}
		}
	}
}

.ant-modal {
	&.modalMetric {
		// max-width: 710px;
		// width: 100% !important;
		width: 700px !important;

		.ant-modal-content {
			height: 710px;
		}

		.ant-modal-body {
			padding: 20px 0 0;
		}

		.ant-modal-header {
			border: none;
			padding-bottom: 0;

			.ant-modal-title {
				font-weight: 600;
				color: @default-color;
				font-family: @primary-font;
			}
		}

		.metricHeader {
			width: 100%;
			padding: 0 20px 20px 20px;
			border-bottom: 1px solid @altralight-color;

			.ant-col-lg-18 {
				padding-left: 5px;
			}

			.ant-col-lg-6 {
				padding-left: 5px;
			}

			.searchInput {
				margin-right: 0;

				.ant-input-search {
					background-color: @lighgrey-color;
					border-radius: 12px;
					height: 42px;

					.ant-input-affix-wrapper {
						background-color: transparent;
						border: none !important;
						outline: none;
						box-shadow: none;
						min-height: 42px;
						height: 42px;
						padding: 5px 15px;

						.ant-input {
							background-color: transparent;
							border: none;
							font-size: 14px;
							font-weight: 400;
							font-family: @secondary-font;
							color: @grey-color;

							&::placeholder {
								font-family: @secondary-font;
							}
						}
					}

					// .ant-input-affix-wrapper-focused{
					// 	border-bottom:1px solid @primary-color !important;
					// 	border-left:1px solid @primary-color !important;
					// 	border-top:1px solid @primary-color !important;

					// }
					// .ant-input{
					// 	&:focus{
					// 		box-shadow:none;
					// 		border-bottom:1px solid @primary-color !important;
					// 		border-left:1px solid @primary-color !important;
					// 		border-top:1px solid @primary-color !important;
					// 		// box-shadow: 0 0 0 1px #4a5cfb;
					// 	}
					// 	&:focus ~ span.ant-input-group-addon{
					// 		border:1px solid @primary-color;
					// 	}
					// }

					.ant-input-group-addon {
						background-color: transparent;
						border: none;

						.ant-btn {
							background-color: transparent;
							box-shadow: none;
							padding: 0 15px 0 0;
							width: auto;
							height: 42px;

							.anticon {
								font-size: 20px;
								font-weight: 700;
								color: @primary-color;
							}
						}
					}
				}

				.ant-input-wrapper {
					&:focus {
						border: 1px solid blue;
					}
				}
			}

			.btn-primary {
				font-size: 16px;
				font-weight: 600;
				font-family: @primary-font;
				padding: 10px 25px;
				width: 100%;
				text-align: center;
				.transition();
				height: 42px;
				background-color: rgba(74, 92, 251, 0.1);

				span {
					color: @primary-color;
				}

				&:hover {
					background: #4a5cfb33;
				}
			}
		}

		.vertiverticalTab {
			height: 580px;

			.ant-tabs-nav {
				.ant-tabs-nav-list {
					.ant-tabs-tab {
						padding: 6px 20px;
						margin-bottom: 8px;
					}
				}

				.ant-tabs-nav-wrap {
					min-width: 230px;
					box-shadow: none;
					border-right: 1px solid @altralight-color;
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 0px;
					border-top-right-radius: 0px;
					height: 100%;
				}
			}

			.ant-tabs-content-holder {
				margin: 0;
				padding: 0px;
				height: 545px;
				// overflow: auto;

				.metricListing {
					width: 100%;
					margin-bottom: 10px;
					background-color: @lighgrey-color;
					border-radius: 12px;
					padding: 15px;

					h5 {
						font-size: 14px;
						font-weight: 600;
						margin: 0;
						color: @default-color;
					}

					ul {
						padding-left: 16px;
						margin: 5px 0 0 0;

						li {
							font-size: 12px;
							font-weight: 400;
							color: @grey-color;
							font-family: @primary-font;
							line-height: 1.4;
						}
					}
				}

				.listingContent {
					height: 495px;
					overflow: auto;
					padding: 20px;
				}

				.selectBtn {
					position: relative;
					padding: 15px 0px;

					&::before {
						position: absolute;
						content: "";
						background-color: #f0f0f0;
						height: 1px;
						width: 100%;
						top: 3px;
					}

					.ant-btn {
						height: 42px;
						width: 125px;
						margin: 10px 20px 10px 0px;
					}
				}
			}
		}

		::placeholder {
			color: #676a78 !important;
			.semiMediumFont();
		}

		// .ant-input-wrapper {
		// 	&:focus {
		// 		box-shadow: 0 0 0 1px #4a5cfb;
		// 		border-radius: 12px;
		// 	}
		// }
	}

	&.newMetricModal {
		width: 100% !important;
		max-width: 420px;

		.ant-modal-content {
			box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
		}

		.ant-modal-header {
			border: none;
			// padding-bottom: 0;
			border-radius: 10px 10px 0 0;
			padding: 24px 24px 0px 24px;

			.ant-modal-title {
				font-weight: 600;
				color: @default-color;
				font-family: @primary-font;
			}
		}

		.ant-form {
			button {
				margin-top: 10px;
				display: inline-block;

				&.ant-btn {
					font-size: 16px;
					font-weight: 600;
					padding: 12px 25px;
					height: auto;
					width: 100%;
				}
			}
		}

		.ant-form-item {
			margin-bottom: 10px;
		}

		.ant-segmented {
			border-radius: 12px;
			// background: @input-field-color;
			width: 100%;

			.ant-segmented-group {
				width: 100%;
				padding: 2px;

				.ant-segmented-item {
					padding: 4px 0px;
					width: 50%;
				}

				.ant-segmented-item-selected {
					border-radius: 10px;

					.ant-segmented-item-label {
						color: @primary-color;
					}
				}
			}

			.ant-segmented-item-label {
				.mediumFont();
				font-size: 14px;
				// color: @font-color;
			}
		}

		.listCategory {
			padding: 0px 10px 20px 10px;
			margin: 10px 0px 0px 0px;
			border-radius: 12px;
			background: #f2f3f7;

			.addButton {
				background-color: transparent;
				box-shadow: none;
				padding: 15px 15px !important;
				margin: 0;

				span {
					color: #676a78;
				}
			}

			.searchName {
				.ant-input {
					padding: 10px 16px 10px 16px !important;
				}

				.ant-input-affix-wrapper {
					background-color: #fff;
					height: 50px;
					border-radius: 10px;
					margin-top: 20px;
					border: none;

					input {
						background-color: #fff;
					}
				}

				.ant-input-affix-wrapper-focused {
					box-shadow: 0px 0px 0px 1px @primary-color;
				}

				::placeholder {
					color: #676a78 !important;
				}
			}

			.ant-list-item {
				padding: 0px 12px;
				background: #fff;
				border-radius: 10px;
				margin: 10px 0px;
			}
		}

		.ant-select-selection-item {
			top: 7px;
		}

		.ant-input {
			padding: 20px 20px 5px 16px !important;
		}
	}
}

.addReferClientBtn {
	margin-right: 30px;

	.commanButton {
		background: rgba(74, 92, 251, 0.1);
		color: @primary-color !important;

		&:hover {
			background-color: #001aff1a;
			color: white;
		}

		svg {
			margin-left: 10px;
		}
	}
}

.ant-tag {
	padding: 8px 20px;
	border-radius: 8px;
	margin-top: 6px;
	border: none;
	background-color: #ec9f58;
	color: #fff;
	.mediumFont();
	font-size: 12px;
}

.referClientModel {
	.ant-modal-content {
		border-radius: 10px;

		.ant-modal-header {
			border-bottom: none;
			padding: 24px 24px 12px 24px;

			.ant-modal-title {
				font-family: @primary-font;
				font-weight: 600;
				font-size: 16px;
				color: #000;
			}
		}

		.ant-modal-body {
			padding: 0px 20px;

			.ant-input {
				margin: 5px 0px;
			}
		}

		.ant-modal-footer {
			border-top: none;
			padding: 0px 20px 24px 20px;

			.ant-btn {
				height: 50px;
				background-color: @primary-color;
				color: #fff;
				border-radius: 10px;
				padding: 16px 20px;
				font-family: @primary-font;
				font-weight: 600;
				font-size: 14px;
			}
		}
	}

	.ant-modal-close {
		top: 8px;
		right: 7px;
	}
}

// .referClientSection
.referClientPoint {
	.EarnPointSection {
		.refferedBlock {
			// background-color: @lighgrey-color;
			// border-radius: 12px;
			// padding: 10px;

			ul {
				// margin: 0 0 10px;
				padding: 0;
				display: flex;
				flex-wrap: wrap;

				li {
					list-style: none;
					font-size: 14px;
					text-align: center;
					font-weight: 400;
					color: #222325;
					position: relative;
					// width: 50%;
					padding: 0px 30px;
					margin-top: -35px;
					margin-bottom: -15px;

					.referNumber {
						display: block;
						font-size: 40px;
						text-align: center;
						color: @primary-color;
						font-weight: 700;
						font-family: @primary-font;
						margin-top: -5px;
						// margin-bottom: -5px;
					}

					span {
						display: block;
						font-size: 14px;
						text-align: center;
						font-weight: 400;
						font-family: @primary-font;
						color: @grey-color;
						margin-top: 40px;
					}

					&::after {
						content: "";
						position: absolute;
						right: 0;
						top: 25%;
						width: 1px;
						height: 90%;
						background-color: @altralight-color;
					}

					&:last-child {
						&::after {
							display: none;
						}
					}
				}

				.referInvited {
					padding-left: 3px;
				}
			}
		}

		.moreBtn {
			padding: 6px 30px;
			border: none;
			height: 45px;
			color: @primary-color;
			font-size: 16px;
			background: rgba(74, 92, 251, 0.1);
			.transition();

			&:hover {
				background-color: #001aff1a;
			}
		}

		h3 {
			margin-bottom: 10px;
		}
	}
}

.goalRangePopup {
	.ant-picker-panel-container {
		.ant-picker-panels {
			width: 100% !important;
		}

		padding: 10px;
	}
}

// -------------------goal date picker footer
.ant-picker-panel-container {
	position: relative;
	padding: 20px 20px 10px 20px;

	.ant-picker-footer {
		// width: 100%;

		.ant-picker-footer-extra {
			line-height: 1.4;
		}
	}

	.checkBox {
		position: absolute;
		top: 0px;
		right: 0;
		left: 0;
		margin: 0 auto;
		background-color: #fdf5ee;

		// padding: 0px 25px;
		.ant-checkbox-wrapper {
			padding: 10px 25px;
			display: flex;
			align-items: center;

			.ant-checkbox {
				margin-right: 10px;
			}
		}

		span {
			color: #000;
			font-family: @primary-font;
			font-size: 14px;
			font-weight: 400;
		}
	}

	.extraMapFooter {
		.footerBtn {
			margin: 10px 0px;

			button {
				width: 45%;
				margin-right: 10px;
				padding: 8px 30px;
				background-color: #f2f3f7;
				color: @grey-color;
				border: none;
				border-radius: 7px;
				cursor: pointer;
			}

			.blueSave {
				background-color: @primary-color;
				color: @white;
			}
		}
	}
}

// --------------------------------------------refer Client Points css starts from here-------------------------------------------------------

.referClientPoint {
	.referClientEarnPoints {
		display: flex;
		align-items: center;

		.earnPointsContent {
			margin-left: 70px;

			h3 {
				.semiMediumFont();
				font-size: 22px;
				width: 60%;
			}

			p {
				.semiMediumFont();
				font-size: 16px;
			}
		}
	}

	.emailInfo {
		background-color: #ffffff;
		margin-top: 20px;
		margin-right: 30px;
		border-radius: 10px;
		position: relative;

		span {
			font-family: @primary-font;
			font-size: 16px;
			font-weight: 600;
		}

		.emailData {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 25px 20px 20px 20px;
			// width:70%;
		}

		.referIcons {
			display: flex;
			align-items: center;
			justify-content: space-between;
			// width: 20%;
			padding: 25px 0px 20px 0px;

			.ant-tag {
				padding: 4px 10px;
				border-radius: 8px;
				margin-top: -6px;
				font-size: 14px;
			}

			svg {
				.transition();
				cursor: pointer;

				&:hover {
					path {
						fill: #d88080;
					}
				}
			}
		}

		&:after {
			position: absolute;
			background-color: #e5e7ec;
			width: 100%;
			height: 1px;
			content: "";
			bottom: -20px;
		}
	}

	.emailContent {
		background-color: #ffffff;
		// margin-top: 20px;
		margin-right: 30px;
		// border-radius: 10px;
		position: relative;
		// width:100%;
		padding: 27px 20px;

		span {
			font-family: @primary-font;
			font-size: 16px;
			font-weight: 600;
		}

		.emailData {
			display: flex;
			align-items: center;
			justify-content: space-between;
			// width:70%;
		}

		.referIcons {
			display: flex;
			align-items: center;
			justify-content: space-between;
			// width: 20%;

			.ant-tag {
				padding: 4px 10px;
				border-radius: 8px;
			}

			svg {
				.transition();
				cursor: pointer;

				&:hover {
					path {
						fill: #d88080;
					}
				}
			}
		}

		// &:after{
		// 	position: absolute;
		// 	background-color: #222325;
		// 	width:97%;
		// 	height:2px;
		// 	content:"";
		// 	bottom:0;
		// }
	}

	.ant-divider-horizontal {
		margin: 0px 0px;
	}

	.warningMsg {
		margin-right: 30px;
		margin-top: 40px;

		.ant-alert-warning {
			background: rgba(236, 159, 88, 0.1);
			border: none;
			padding: 15px 10px 15px 20px;
			height: 60px;
			border-radius: 10px;

			.ant-alert-content {
				.ant-alert-message {
					color: rgb(236, 159, 88);
					font-family: @primary-font;
					font-weight: 600;
					font-size: 13px;
					padding-left: 5px;
				}
			}
		}
	}

	.addReferClientBtn {
		margin-right: 30px;

		.commanButton {
			background: rgba(74, 92, 251, 0.1);
			color: @primary-color !important;

			&:hover {
				background-color: #001aff1a;
			}

			svg {
				margin-left: 10px;
			}
		}
	}
}

.headerSection {
	display: flex;
	align-items: center;

	.menuIcon {
		background-color: transparent !important;
		border: none;
		padding: 0;
		margin-right: 12px;
		line-height: 2;
		display: inline-block;
		height: auto;
		box-shadow: none;
		border-radius: 0px;
		top: -2px;
	}

	.settingIcon {
		// margin-top: -22px;
		margin-top: -10px;
		margin-left: 15px;

		svg {
			cursor: pointer;

			&:hover {
				path {
					fill: @primary-color;
				}
			}
		}
	}
}

.tabButton {
	display: flex;
	align-items: center;
	margin-right: 15px;

	.addIcon {
		margin-right: 15px;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 9;

		svg {
			cursor: pointer;

			path {
				stroke: @grey-color;
			}
		}
	}

	.animatedAddIcon {
		position: relative;
		background-color: @primary-color;

		svg {
			cursor: pointer;

			path {
				stroke: @white-color;
			}
		}

		&::after,
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 30px;
			height: 30px;
			background: @primary-color;
			border-radius: 50%;
			z-index: -1;
			animation: grow 1s ease-in-out infinite;
		}

		&::after {
			background: rgba(74, 92, 251, 0.4);

			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 40px;
				height: 40px;
				background: rgb(95, 132, 255);
				border-radius: 50%;
				z-index: -1;
				animation: grow 1s ease-in-out infinite;
			}
		}

		&::before {
			background: rgba(74, 92, 251, 0.6);
			animation-delay: -0.5s;
		}

		@keyframes grow {
			0% {
				transform: scale(1, 1);
				opacity: 1;
			}

			100% {
				transform: scale(1.8, 1.8);
				opacity: 0;
			}
		}
	}

	.editIcon {
		margin-right: 15px;

		svg {
			cursor: pointer;

			&:hover {
				path {
					fill: @primary-color;
				}
			}
		}
	}

	.moreIcon {
		svg {
			cursor: pointer;

			&:hover {
				path {
					fill: @primary-color;
				}
			}
		}
	}
}

// ---------------------------------------------------------------addon after floating effect---------------------------------------------------------------
.ant-input {
	position: relative;

	&::placeholder {
		color: transparent !important;
	}

	&:focus {
		box-shadow: 0 0 0 1px @primary-color;
	}
}

.ant-input-affix-wrapper-focused {
	border: 1px solid @primary-color;
}

// .ant-form-item-control-input-content {
// 	padding: 0px 2px;

// 	span.ant-input-group-addon {
// 		background: no-repeat;
// 		border: 0;
// 		position: absolute;
// 		z-index: 9;
// 		transform-origin: 0 0;
// 		.transition();
// 		top: 50%;
// 		transform: translate(0, -50%);
// 		padding-left: 14px;
// 		color: @grey-color;
// 	}

// 	input:focus~span.ant-input-group-addon,
// 	input:not(:placeholder-shown)~span.ant-input-group-addon {
// 		transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
// 		top: 25px;
// 		font-size: 12px;
// 	}

// 	span.ant-input-group-addon {
// 		&:focus {
// 			transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
// 			top: 30px;
// 			font-size: 12px;
// 		}

// 	}

// }

.ant-form-item-extra {
	min-height: 24px;
	position: absolute;
	right: 15px;
	top: 25%;
	cursor: pointer;
	z-index: 99;
}

// ------------------------------------------------------add template css starts from here--------------------------------------------------------
.addTemplateModel {
	// width: 700px !important;
	// height: 500px !important;

	// .ant-modal-body {
	// 	height: 500px;
	// 	overflow: auto;
	// }
	// .ant-input{
	// 	padding: 20px 20px 5px 16px !important;
	// }
	.ant-modal-footer {
		border-top: none;
	}

	.ant-input {
		padding: 20px 40px 5px 16px !important;
	}

	.TemplateRow {
		display: flex;
		align-items: center;
		margin-top: 20px;

		.tamplateContent {
			padding: 0px 15px 0px 0px;

			.ant-image {
				border-radius: 10px;
				border: 1px solid #f0f0f0;
				padding: 10px;
				.transition();

				&:hover {
					border: 1px solid @grey-color;
				}
			}

			h5 {
				margin-bottom: 0px;
				padding-left: 5px;
				color: #000;
				.mediumFont();
				font-size: 14px;
				word-break: break-all;
			}
		}
	}

	.ant-select-focused {
		// border: 1px solid @primary-color;
		border-radius: 12px;
	}

	.ant-select-selection-placeholder {
		padding: 0px 0px 0px 0px !important;
	}

	.existingLink {
		.mediumFont();
		display: flex;
		justify-content: center;
		padding: 20px 0px 5px 0px;
	}

	.ant-select-selection-item {
		top: 7px;
	}
}

.addEditModel {
	width: 422px !important;

	.ant-modal-content {
		overflow: auto;

		.ant-modal-body {
			padding: 10px 24px 0px 24px;
			height: 300px;
			overflow: auto;
		}
	}

	.ant-input {
		padding: 20px 40px 5px 16px !important;
	}

	.ant-modal-header {
		padding: 24px 24px 10px 24px;
	}

	.ant-modal-footer {
		border-top: none;
		padding: 20px 24px 20px 24px;

		button {
			height: 50px;

			span {
				.mediumFont();
				font-size: 14px;
			}
		}
	}
}

// --------------------------------------------------------delete button comman css starts here-----------------------------------------

.deleteModal {
	.ant-modal-body {
		// padding: 24px 24px 0px 24px;
		padding: 30px 60px 0px 60px;

		.deleteTitle {
			// padding-top: 10px;
			.mediumFont();
			font-size: 20px;
			color: @default-color;
		}

		.deleteContent {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			.mediumFont();
			font-size: 14px;
		}

		.deleteMtBoxContent {
			.semiMediumFont();
			font-size: 16px;
			text-align: center;
		}
	}

	.ant-modal-footer {
		border-top: none;
		display: flex;
		padding: 20px;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.deleteBtn {
			background: @colorPink;
			color: #fff;
			height: 52px;
			width: 100%;
			font-size: 16px;
			.mediumFont();

			.transition();

			&:hover {
				background-color: #ca4b4b;
			}
		}

		.ant-btn.cancelBtn {
			background-color: @lighgrey-color;
			margin-left: 0px;
			color: @grey-color;
			margin-top: 10px;
			height: 52px;
			width: 100%;
			.mediumFont();
			font-size: 16px;

			span {
				color: @grey-color;
				// transition: @transition;
			}

			&:hover {
				span {
					color: @primary-color;
				}
			}
		}
	}
}

// --------------------------------------------------comman css for modal----------------------------------------------------
.ant-modal-header {
	padding: 24px 24px 20px 24px;
	border-bottom: none;

	.ant-modal-title {
		.mediumFont();
		font-size: 16px;
		color: @default-color;
	}
}

.ant-modal-close {
	top: 8px;
	right: 4px;
}

.ant-modal-content {
	border-radius: 10px;
}

.ant-modal-body {
	padding: 0px 24px 24px 24px;

	.ant-btn {
		width: 100%;
		height: 52px;
		background-color: @primary-color;

		span {
			color: #fff;
			.mediumFont();
			font-size: 16px;
		}
	}
}

.ag-theme-alpine .ag-row {
	margin-bottom: 50px;
}

// ------------------------------------------------edit prjoect modal css starts from here-----------------------------------------
.editProjectModal {
	.ant-form-item {
		margin-bottom: 20px !important;
	}

	.ant-input {
		padding: 20px 20px 5px 16px !important;
	}

	.deleteButton {
		background-color: transparent;
		margin-top: 10px;
		box-shadow: none;

		span {
			color: @primary-color !important;
			.transition();
		}

		&:hover {
			background-color: transparent;
		}
	}
}

.react-flow__attribution {
	display: none !important;
}

// ----------------------------------------------edit prjoect modal css ends here------------------------------------------------

// --------------------------------------------------comman error message css-----------------------------------------------------

.ant-form-item-explain-error {
	padding-left: 16px;
}

// -----------------------------------------team stack edit button modal css starts from here-------------------------------------

.editSubcategories {
	.ant-input-group-addon {
		border: none;
		background-color: transparent;
		padding: 0px 15px 0px 0px;

		svg {
			cursor: pointer;

			&:hover {
				rect {
					fill: @primary-color;
				}
			}
		}

		.deleteIcon {
			position: absolute;
			right: 10px;
			top: 15px;
			z-index: 99;
		}
	}

	.ant-form-item-label {
		left: 55px !important;
	}

	.ant-form-item-explain-error {
		padding-left: 53px;
	}
}

// -----------------------------------------team stack edit button modal css ends from here---------------------------------------

.ant-select-focused {
	box-shadow: 0 0 0 1px @primary-color;
	border-radius: 10px;
}

// ---------------duplicate existing modal------
.existingModal {
	.ant-select-selection-item {
		top: 7px;
	}

	.ant-select-dropdown {
		width: 100% !important;
		max-width: 500px !important;
		min-width: 500px !important;
	}
}

// ----text box name

.textBoxArea {
	.ant-form-item-label {
		background: #f2f3f7;
		width: 90%;
		padding: 5px 0px 0px 0px;
		top: 0px !important;
	}

	textarea {
		&.ant-input {
			background-color: @lighgrey-color;
			border: none !important;
			resize: none;
			outline: none !important;
			padding: 10px;

			&::placeholder {
				color: #000 !important;
			}
		}
	}
}

.item {
	.textBoxArea {
		textarea {
			&::placeholder {
				color: @grey-color !important;
			}
		}
	}
}

.calculationColorOptions {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	width: 100px;
	justify-content: space-between;

	.ant-form-item {
		padding: 0;
		margin: 0;
	}

	.ant-select {
		padding-top: 3px;

		.ant-select-selector {
			border: none;
			background-color: transparent !important;
			height: auto;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			border: none !important;
			outline: none !important;
			box-shadow: none !important;
			padding-left: 0px;

			.ant-select-selection-item,
			.ant-select-selection-placeholder {
				font-size: 14px;
				font-family: @secondary-font;
				font-weight: 500;
				color: @black-color;
				line-height: 1;
			}
		}
	}

	.ant-select-focused {
		box-shadow: none;
	}

	.ant-select-selection-item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		>div {
			width: 20px;
			height: 20px;
			border-radius: 50%;

			&.colorPink {
				background-color: @colorPink;
			}

			&.lightPinkColor {
				background-color: @lightPinkColor;
			}

			&.colorOrange {
				background-color: @colorOrange;
			}

			&.lightOrangeColor {
				background-color: @lightOrangeColor;
			}

			&.colorGreen {
				background-color: @colorGreen;
			}

			&.lightGreenColor {
				background-color: @lightGreenColor;
			}

			&.colorBlack {
				background-color: #000;
			}
		}
	}

	.ant-select-selection-item {
		top: 3px;
		left: 5px;
	}

	.ant-form-item-label {
		top: 10px !important;
		position: relative !important;
		left: 0px !important;

		label {
			color: @grey-color;
			font-family: "GT Walsheim Pro";
			font-weight: 500;
			font-size: 14px;
		}
	}
}

.bar-chart-container .chartjs-render-monitor .chartjs-bar {
	margin-right: 100px;
}

.datePopover {
	.ant-popover-title {
		padding: 0px 0px;
		display: none;
	}

	.ant-popover-inner-content {
		width: 100%;
	}
}

.alignLine {
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-left: 5px;
	}
}

.editMetricText {
	.ant-form-item-control-input {
		.ant-form-item-control-input-content {
			textarea {
				&.ant-input {
					min-height: 70px !important;
				}
			}
		}
	}
}