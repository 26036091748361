.modContainerDashboard {
	width: 100%;

	h1 {
		// margin-bottom: 30px;
		margin-bottom: 15px;
	}

	.headerTabs {
		>.ant-tabs-nav {
			margin: 0;
		}

		.ant-tabs-tab {
			margin: 0 10px 0 0;
			padding: 0;

			.ant-tabs-tab-btn {
				background-color: @Violetgrey-color;
				padding: 14px 17px 14px 17px;
				border-radius: 14px 14px 0px 0px;
				font-size: 14px;
				font-family: @primary-font;
				color: @default-color;
				.transition();

				// &:hover{
				// 	transform: translateY(-2px);
				//   }
				&:hover {
					color: @primary-color;
				}
			}

			&:last-child {
				margin: 0;
			}

			&.ant-tabs-tab-active {
				.ant-tabs-tab-btn {
					background-color: @white-color;
					text-shadow: 0 0 0.7px #4a5cfb, 0 0 0.7px #4a5cfb;
					color: @primary-color;
				}
			}
		}

		.ant-tabs-nav-list {
			.ant-tabs-ink-bar {
				display: none;
			}
		}

		.linkTab {
			.ant-tabs-nav {
				// padding: 12px 0px 0;
				padding: 0px 0px 0;
				margin: 0px 0px 0px 0px;
			}

			.ant-tabs-tab {
				margin-left: 30px;
				margin-right: 0;

				.ant-tabs-tab-btn {
					padding: 15px 0;
					background-color: transparent;
					font-size: 16px;
					font-weight: 600;
					color: @grey-color;
					text-shadow: none !important;
				}

				&.ant-tabs-tab-active {
					.ant-tabs-tab-btn {
						color: @primary-color;
					}
				}
			}

			.ant-tabs-nav-list {
				.ant-tabs-ink-bar {
					display: block;
					font-size: 16px;
					font-weight: 600;
				}
			}

			.ant-tabs-nav-list {
				.ant-tabs-tab-active {
					border-bottom: 2px solid #4a5cfb !important;
				}
			}
		}

		.buttonTab {
			.ant-tabs-nav {
				padding: 0 24px;

				&::before {
					display: none;
				}

				.childTabWrap {
					padding: 0;
				}
			}

			.ant-tabs-nav-wrap {
				justify-content: flex-end;
				order: 2;
			}

			.ant-tabs-nav-list {
				background-color: @lighgrey-color;
				padding: 3px;
				border-radius: 12px;

				.ant-tabs-ink-bar {
					display: none;
				}

				.ant-tabs-tab {
					margin: 0;

					.ant-tabs-tab-btn {
						padding: 10px 22px;
						line-height: 1.2;
						font-size: 14px;
						font-weight: 600;
						background-color: transparent;
						border-radius: 12px;

						span {
							svg {
								margin-right: 10px;
								display: inline-block;
								vertical-align: middle;

								path {
									fill: @grey-color;
								}
							}
						}
					}

					&.ant-tabs-tab-active {
						.ant-tabs-tab-btn {
							background-color: @white-color;
							color: @default-color;

							span {
								svg {
									path {
										fill: @primary-color;
									}
								}
							}
						}
					}
				}
			}

			.ant-tabs-content-holder {
				background-color: @body-bg;
				padding: 15px;
			}
		}

		.ant-tabs-content-holder {
			margin: 0px -24px;
			background-color: @white-color;

			.ant-tabs-content-holder {
				margin: 0;
				background-color: @white-color;
				padding: 0;
			}
		}
	}

	h2 {
		font-size: 24px;
		font-weight: 700;
		font-family: @secondary-font;
		text-transform: uppercase;
	}

	.groupWrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		// margin-right: 30px;

		small {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
			font-family: @secondary-font;
			font-weight: 400;
			margin-right: 15px;
		}

		.ant-avatar {
			margin-left: -15px;
			border: 2px solid @white-color;
			background-color: rgba(0, 0, 0, 0.7);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			&:first-child {
				margin-left: 0;
			}

			.ant-avatar-string {
				cursor: pointer;
			}
		}

		.editGroup {
			margin-left: 5px;

			.btn-primary {
				border-radius: 50%;
				width: 36px;
				height: 36px;
			}
		}

		.publish-btn {
			font-size: 16px;
			font-weight: 600;
			font-family: @primary-font;
			margin-right: 0;
			padding: 8px 40px;
			height: 44px;
			background-color: #fff;
			border: 1px solid @green-color;
			color: @green-color;
			margin-right: 20px;

			&:active {
				background-color: @green-color;
				color: #fff;
			}
		}

		.active {
			background-color: @green-color;
			color: #fff;
		}

		a {
			svg {
				margin-left: 8px;
			}
		}
	}

	.dataSwitch {
		span {
			margin-right: 5px;
			.mediumFont();
		}

		button {
			margin-right: 5px;
		}
	}

	.childTabWrap {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 10px 24px 10px;

		.childLeft {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.dateRange {
				background-color: @lighgrey-color;
				// padding: 7px 14px;
				padding: 4px 14px;
				border-radius: 12px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin: 0 20px;
				cursor: pointer;

				small {
					font-size: 14px;
					color: @grey-color;
					font-family: @secondary-font;
					font-weight: 400;
					// margin-right: 10px;
				}

				.rangeAlignment {
					display: flex;
					align-items: center;

					.week {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						position: relative;

						.ant-form-item {
							margin: 0;

							.ant-select-selector {
								background-color: transparent;
								border: none !important;
								outline: none !important;
								box-shadow: none !important;
							}

							.ant-select-arrow {
								display: none;
							}
						}

						span {
							font-size: 14px;
							color: @default-color;
							font-family: @secondary-font;
							font-weight: 500;
							margin-right: 0;
							padding: 0;
						}

						svg {
							path {
								fill: @primary-color;
							}
						}

						// .ant-form-item-control{
						// 	width:100px;
						// }
						button {
							padding: 0px 5px;
							background: transparent;
							box-shadow: none;

							.ant-form-item-control {
								display: flex;
								align-items: center;
								justify-content: center;
								flex-direction: row;
							}

							#category_extra {
								margin-top: 5px;
								margin-right: 5px;
								position: static;
								right: 0;
								top: 0;
								margin-top: 5px;
								margin-right: -5px;
							}

							.ant-select-selector {
								padding: 0px 5px 0px 0px;
							}


						}

						.ant-select-focused {
							box-shadow: none;
						}

						&:after {
							position: absolute;
							height: 75%;
							width: 1px;
							right: 0;
							background-color: #E5E7EC;
							content: "";
						}

						small {
							padding: 0px 7px;

							span {
								padding: 0px 5px;
							}
						}
					}

					.start {

						display: flex;
						flex-wrap: wrap;
						align-items: center;
						padding-left: 7px;

						.ant-form-item {
							margin: 0;

							.ant-select-selector {
								background-color: transparent;
								border: none !important;
								outline: none !important;
								box-shadow: none !important;
							}

							.ant-select-arrow {
								display: none;
							}
						}

						span {
							font-size: 14px;
							color: @default-color;
							font-family: @secondary-font;
							font-weight: 500;
							margin-right: 0;
							padding: 0;
						}

						svg {
							path {
								fill: @primary-color;
							}
						}

						// .ant-form-item-control{
						// 	width:100px;
						// }
						button {
							padding: 0px 0px;
							background: transparent;
							box-shadow: none;

							.ant-form-item-control {
								display: flex;
								align-items: center;
								justify-content: center;
								flex-direction: row;
							}

							#category_extra {
								margin-top: 5px;
								margin-right: 5px;
								position: static;
								right: 0;
								top: 0;
								margin-top: 5px;
								margin-right: -5px;
							}

							.ant-select-selector {
								padding: 0px 5px 0px 0px;
							}


						}

						.ant-select-focused {
							box-shadow: none;
						}

						small {
							padding: 0px 7px;

							span {
								padding: 0px 5px;
							}
						}

					}
				}
			}
		}

		.childRight {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.searchInput {
				margin-right: 15px;

				.ant-input-search {
					background-color: @lighgrey-color;
					border-radius: 12px;

					.ant-input-affix-wrapper {
						background-color: transparent;
						border: none !important;
						outline: none;
						box-shadow: none;
						min-height: 40px;
						padding: 5px 15px;

						.ant-input {
							background-color: transparent;
							border: none;
							font-size: 14px;
							font-weight: 400;
							font-family: @secondary-font;
							color: @grey-color;
						}
					}

					.ant-input-group-addon {
						background-color: transparent;
						border: none;

						.ant-btn {
							background-color: transparent;
							box-shadow: none;
							padding: 0 15px 0 0;
							width: auto;

							.anticon {
								font-size: 20px;
								font-weight: 700;
								color: @primary-color;
							}
						}
					}
				}
			}

			.ant-segmented {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				background-color: @lighgrey-color;
				padding: 3px;
				border-radius: 12px;

				.ant-segmented-item {
					// padding: 8px 10px;
					padding: 4px 10px;
					background-color: transparent;
					font-size: 14px;
					font-family: @primary-font;
					color: @grey-color;
					height: auto;
					border-radius: 10px !important;
					box-shadow: none;
					font-weight: 600;

					span {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
					}

					svg {
						margin-right: 10px;
					}

					&.ant-segmented-item-selected {
						background-color: #fff;
						// color: @default-color;
						color: @primary-color;
						border-radius: 10px !important;

						svg {
							rect {
								fill: @primary-color;
							}

							path {
								fill: @primary-color;
							}
						}


					}

					&:hover {
						color: @primary-color;

						svg {
							rect {
								fill: @primary-color;
							}

							path {
								fill: @primary-color;
							}
						}
					}
				}
			}

			@media screen and(max-width:1250px) {
				flex-wrap: nowrap;
			}
		}
	}



	.teamActivitySection {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 24px;
		border-top: 1px solid @altralight-color;

		.companyWrap {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.companyInfo {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin-right: 30px;

				.thumb {
					img {
						max-width: 46px;
					}
				}

				.content {
					padding-left: 22px;
				}

				h3 {
					font-size: 22px;
					font-weight: 700;
					font-family: @secondary-font;
					margin: 0;
					line-height: 1;
				}

				small {
					color: rgba(0, 0, 0, 0.5);
					font-size: 12px;
					font-family: @primary-font;
				}
			}

			.ant-avatar-group {
				.ant-avatar-lg {
					width: 36px;
					height: 36px;
					margin-left: -15px;

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}

		.editListing {
			ul {
				margin: 0;
				padding: 0;
				display: flex;
				flex-wrap: wrap;

				li {
					list-style: none;
					margin-right: 10px;

					&:last-child {
						margin-right: 0;
					}

					a {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						font-size: 14px;
						font-weight: 600;
						font-family: @primary-font;
						color: @primary-color;
						padding: 10px 20px;
						background: rgba(74, 92, 251, 0.1);
						border-radius: 10px;
						.transition;

						svg {
							margin-left: 12px;
						}

						&:hover {
							background: rgba(74, 92, 251, 0.2);
						}
					}
				}
			}
		}
	}

	.EditDragDrop {
		max-width: 220px;
		min-width: 220px;
		text-align: center;
		margin: 0;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 9;

		h2 {
			font-size: 24px;
			font-weight: 700;
			font-family: @secondary-font;
			text-transform: uppercase;
		}

		.menuAccordion {
			margin-bottom: 0;
			text-align: left;
		}

		.ant-collapse {
			background-color: @white-color;
			box-shadow: 0px 4px 20px rgba(40, 43, 54, 0.08);
			border-radius: 10px;
			border: none;

			.ant-collapse-item {
				border: none;
			}

			.ant-collapse-header {
				font-size: 12px;
				font-family: @secondary-font;
				color: rgba(0, 0, 0, 0.5);
				font-weight: 400;
				padding: 20px 16px;
				line-height: 1.4;

				.ant-collapse-expand-icon {
					svg {
						font-size: 14px;

						path {
							fill: @primary-color;
						}
					}
				}
			}

			.ant-collapse-content {
				border: none;
				background-color: @white-color;
				padding: 0 16px;

				.ant-collapse-content-box {
					padding: 0;
				}

				.submenuList {
					margin: 0;
					padding: 0;

					li {
						list-style: none;
						margin-bottom: 22px;
						display: inline-block;
						width: 100%;

						a {
							position: relative;
							padding-left: 32px;
							font-size: 14px;
							font-family: @primary-font;
							color: @default-color;
							font-weight: 600;

							svg {
								position: absolute;
								left: 0;
								top: 0;

								path {
									.transition;
								}
							}

							&:hover {
								color: @primary-color;

								svg {
									path {
										fill: @primary-color;
									}
								}
							}
						}

						&.pieChart {
							:hover {
								svg {

									path {
										stroke: @primary-color;
										fill: transparent;
									}

								}
							}
						}
					}
				}
			}
		}
	}

	.horizontalFlow {
		// width: 100vw;
		width: 100%;
		// height: 100vh;
		// height: 80vh;
		height: 74vh;
		padding-left: 0;
		position: relative;

		.react-flow__node-default,
		.react-flow__node-custom,
		.react-flow__node-output {
			min-width: 200px;
			padding: 0;
			box-shadow: none !important;
			border: none;
			border-radius: 0;
			background-color: transparent;
			text-align: left;

			.userNode {
				width: 300px;
				padding: 5px;
				box-shadow: none;
				border: 1px dashed @greyborder-color;
				border-radius: 15px;
				background-color: transparent;
				text-align: left;
			}

			.react-flow__handle {
				background-color: transparent;
				border-color: #494949;
			}

			h5 {
				margin: 0;
				// font-size: 14px;
				color: @default-color;
				// font-family: @secondary-font;
				font-size: 20px;
				font-weight: 700;
			}

			&.mapHeading {
				border: none;
				width: 200px;
				min-height: 100px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				background-color: @white-color;
				border-radius: 15px;

				&.selected {
					border: none;
					box-shadow: none;
				}
			}

			.userInfo {
				background-color: @white-color;
				box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
				border-radius: 15px;
				padding: 20px 20px 20px 70px;
				position: relative;
				margin-bottom: 5px;

				.thumb {
					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
					width: 40px;

					img {
						max-width: 100%;
						border-radius: 50%;
					}
				}

				h2 {
					font-size: 16px;
					font-weight: 500;
					color: @default-color;
					margin: 0;
					text-transform: none;
					font-family: @secondary-font;
					line-height: 1.2;
				}

				h4 {
					font-size: 14px;
					font-weight: 500;
					color: @grey-color;
					margin: 0;
					text-transform: none;
					font-family: @secondary-font;
					line-height: 1.2;
				}
			}

			.userNode {
				h3 {
					font-size: 14px;
					font-weight: 700;
					color: @grey-color;
					margin: 0;
					text-transform: none;
					font-family: @secondary-font;
					line-height: 1.2;
					width: 100%;
					text-align: center;
					padding: 14px;
					border-bottom: 1px solid @altralight-color;
				}

				h6 {
					font-size: 12px;
					font-weight: 400;
					color: @grey-color;
					font-family: @secondary-font;
					margin-bottom: 8px;
					text-align: center;
				}

				#skill {
					display: flex;
					flex-wrap: wrap;
					background-color: @white-color;
					box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
					border-radius: 15px;

					#skills {
						width: 50%;
						padding: 10px;
						border-right: 1px solid @altralight-color;

						&:last-child {
							border-right: none;
						}

						ul {
							margin: 0 0 0 16px;
							padding: 0;
						}
					}
				}
			}

			&.selected {
				box-shadow: none;
			}

			&.scoreCardHeading {
				border: none;
				width: 200px;
				min-height: 130px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				background-color: @white-color;
				border-radius: 15px;

				&.selected {
					border: none;
					box-shadow: none;
				}

				h5 {
					// font-size: 20px;
					font-weight: 500;
				}
			}

			.metricCategory {
				border: none;
				padding: 10px;
				width: 180px;
				min-height: 100px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				background-color: @white-color;
				box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
				border-radius: 15px;
				position: relative;

				.wrap {
					width: 100%;
					text-align: center;

					.profilePic {
						position: absolute;
						right: 10px;
						top: 10px;

						img {
							border-radius: 50%;
							max-width: 24px;
						}
					}

					h5 {
						font-size: 20px;
					}
				}

				h5 {
					// font-size: 14px;
					font-weight: 700;
					// font-family: @secondary-font;
					color: @default-color;
				}
			}

			&.parent {
				padding: 5px;
				border: 1px dashed @greyborder-color;
				border-radius: 15px;
				background-color: transparent;
			}

			.metricBox {
				background-color: @white-color;
				box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
				border-radius: 14px;
				padding: 10px;
				margin-bottom: 5px;

				.Title {
					width: calc(100% - 30px);
					position: relative;

					.status {
						position: absolute;
						left: 0;
						top: 4px;
						width: 9px;
						height: 9px;
						border-radius: 50%;

						&.colorPink {
							background-color: @colorPink;
						}

						&.lightPinkColor {
							background-color: @lightPinkColor;
						}

						&.colorOrange {
							background-color: @colorOrange;
						}

						&.lightOrangeColor {
							background-color: @lightOrangeColor;
						}

						&.colorGreen {
							background-color: @colorGreen;
						}

						&.lightGreenColor {
							background-color: @lightGreenColor;
						}
					}

					.colorGreen {
						background: #AAD880;
					}

					.colorPink {
						background: #D88080;
					}

					.colorOrange {
						background: #EC9F58;
					}

					.colorBlack {
						background-color: #000;
					}

					.alignLeft {
						text-align: left;
					}

					.alignRight {
						text-align: right;
					}

					.alignCenter {
						text-align: center;
					}

					.size14 {
						font-size: 14px;
					}

					.size16 {
						font-size: 16px;
					}

					.size18 {
						font-size: 18px;
					}

					.size20 {
						font-size: 20px;
					}

					.size22 {
						font-size: 22px;
					}

					.size24 {
						font-size: 24px;
					}

					.defaultFont {
						font-family: @secondary-font;
					}

					.fontPopins {
						font-family: 'Poppins', sans-serif;
					}

					.fontRoboto {
						font-family: 'Roboto', sans-serif;
					}

					.fontInter {
						font-family: 'Inter', sans-serif;
					}

					h5 {
						font-weight: 500;
						position: relative;
						margin: 0;
						padding-left: 15px;
						line-height: 1.2;

					}



				}

				.wrap {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 5px;

					h6 {
						// word-break: break-all;
						word-break: break-word;
						font-size: 14px;
						font-weight: 400;
						font-family: @secondary-font;
						color: @default-color;

					}

					.profilePic {
						padding-bottom: 10px;
					}
				}

				.goal {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					gap: 7px;
					align-items: center;
					margin: 8px 0;
					position: relative;

					.icon {
						position: absolute;
						// left: 11px;
						left: 0;
						top: -30px
					}

					span {
						font-size: 22px;
						font-weight: 500;
						font-family: @secondary-font;
						color: @default-color;
						line-height: 1;
					}
				}

				.goalWrap {
					display: flex;
					flex-wrap: wrap;
					gap: 5px;
					font-size: 10px;
					color: @grey-color;
					font-weight: 400;
					font-family: @secondary-font;
					margin-top: 20px;

					strong {
						font-weight: 700;
						color: @default-color;
					}

					.date {
						font-family: @secondary-font;
						font-size: 10px;
						font-weight: 400;
						text-align: center;
						color: @grey-color;

					}
					.goalAmount{
						word-break: break-word;
					}
				}

				.progressBar {
					.ant-progress-line {
						width: 92%;
					}

					.ant-progress-inner {
						background-color: @altralighgrey-color;

						.ant-progress-bg {
							background-color: @green-color;
						}
					}

				}




			}

			.metricBoxCalculation {
				.wrap {
					.status {
						margin: 8px 0px;
					}

					h5 {
						margin-left: 15px;
						// word-break: break-all;
						word-break: break-word;
					}
				}
			}

			.textField {
				background-color: @Violetgrey-color;
				padding: 15px;
				border-radius: 12px;
				margin-bottom: 20px;

				p {
					padding: 0;
					margin: 0;
					font-size: 14px;
					font-weight: 400;
					color: @black-color;
					font-family: @secondary-font;
				}
			}

			.button {
				margin-top: 30px;

				button {
					&.btn-primary {
						font-size: 16px;
						font-weight: 600;
						padding: 12px 25px;
						height: auto;
						margin: 0 auto;
					}
				}
			}

			.mapHeading {
				// word-break: break-all;
				word-break: break-word;
				padding: 0px 10px;

			}
		}

		.react-flow__panel {
			margin: 15px 15px 100px 15px;

			// box-shadow:none;
			.react-flow__controls-button {
				width: 28px;
				height: 28px;
				border-bottom: none;

				svg {
					path {
						fill: @primary-color;
					}
				}
			}
		}

		.react-flow__node-toolbar {
			button {
				background-color: @primary-color;
				border: none;
				box-shadow: none;
				margin: 0px 3px;
				color: #fff;
				border-radius: 5px;
				.semiMediumFont();
				text-transform: capitalize;
				padding: 3px 5px;
			}
		}

		.btnLayout {
			position: absolute;
			right: 0;
			z-index: 9;
			padding-right: 20px;
			display: flex;
			flex-wrap: wrap;




			.backPrevBtn {
				background-color: #fff;
				max-width: 120px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 5px 12px;
				border-radius: 10px;
				margin-right: 10px;
			}

			.archiveBtn {
				button {
					padding: 8px 12px;
					display: flex;
					align-items: center;
					border: none;
					background: #fff;
					border-radius: 10px;
					max-width: 100px;
					width: 100px;
					justify-content: space-around;
					cursor: pointer;
					.transition();

					span {
						font-family: @primary-font;
						font-size: 14px;
						font-weight: 600;
						color: @grey-color;


					}

					svg {
						margin-right: 5px;
					}

					&:hover {
						svg {
							path {
								fill: @primary-color;
							}
						}

						span {
							color: @primary-color;
						}
					}
				}
			}
		}

		@media screen and(max-width:1560px) {
			height: 73vh;
		}

		@media screen and(max-width:1200px) {
			height: 66vh;
		}

	}

	.mainSectionWrap {
		background-color: @body-bg;
		padding: 10px;
		position: relative;

		.tabFormWrap {
			.ant-form-item-control-input-content {
				padding: 0px 2px;

				span.ant-input-group-addon {
					background: no-repeat;
					border: 0;
					position: absolute;
					z-index: 9;
					transform-origin: 0 0;
					.transition();
					top: 50%;
					transform: translate(0, -50%);
					padding-left: 14px;
					color: @grey-color;
					.transition();

					svg {
						&:hover {
							path {
								fill: #ec9f58;
							}
						}
					}
				}

				input:focus~span.ant-input-group-addon,
				input:not(:placeholder-shown)~span.ant-input-group-addon {
					// transform: scale(0.95) translateY(-1.3rem) translateX(0.15rem);
					right: 15px;
					font-size: 12px;
				}
			}

			.ant-form-item-label {
				// background: #f2f3f7 !important;
				// width: 90% !important;
				// width: 37%;
				padding: 5px 0px 0px 0px !important;
				top: 0px !important;
			}

			.dropdownWrap {
				.ant-form-item-label {

					width: 90% !important;

				}
			}

			textarea {
				padding: 23px 16px 5px 16px !important;
			}

			.teamBtn {
				background-color: @primary-color;
				color: #fff;
				.transition();

				&:hover {
					background-color: @primary-color;
					color: #fff;
				}
			}

			.dropdownWrap {
				.ant-form-item-label {
					left: 15px !important;
					top: 10px !important;
				}
			}

			.draggerBackground {
				border-radius: 50%;
				// height: 98px;
				// width: 95px;
				height: 90px;
				width: 90px;
				position: relative;
				left: 113px;
				bottom: 10px;
				background: #F2F3F7;
				padding: 10px;
				margin-top: 40px;
				// .ant-upload-list-item-actions {
				// 	display: flex;

				// 	button {
				// 		background-color: transparent !important;

				// 		&:hover {
				// 			background-color: transparent !important;
				// 		}
				// 	}
				// }

				.ant-upload-drag-icon {
					position: relative;
					background: rgb(255, 255, 255);
					height: 30px;
					width: 30px;
					border-radius: 50%;
					margin-bottom: 6px;
					left: 22px;

					img {
						position: absolute;
						top: 2px;
						left: 3px;
					}
				}

				.ant-upload-drag {
					height: 80px;
					width: 80px;
					margin: 0;
					border-radius: 50%;
					background: transparent;
					left: -4px;
					top: -1px;
					border: none;
				}

				.ant-upload-list-picture-card {
					.ant-upload-list-picture-card-container {
						width: 70px;
						height: 70px;
						margin: 0 0px 0px 0;
						border-radius: 50%;

						.ant-upload-list-item {
							height: 80px;
							width: 80px;
							margin: 0;
							position: relative;
							border-radius: 50%;
							left: -15px;
							bottom: 14px;
							border: none;

							.ant-upload-list-item-info {
								border-radius: 50%;
								height: 80px;
								width: 80px;
							}

							.ant-upload-list-item-actions {
								display: flex;
								top: 50px;
								left: 45px;

								.ant-btn {

									background-color: transparent !important;

								}
							}
						}
					}
				}
			}

		}

		.teamMemberEdit {
			.ant-form-item-extra {
				min-height: 24px;
				position: absolute;
				right: 15px;
				top: 25%;
				cursor: pointer;
			}

			.ant-select-selection-placeholder {
				padding: 15px 0px 0px 0px;
			}
		}

		.ant-drawer-header {
			border: none;
			padding-bottom: 0;

			.ant-drawer-close {
				position: absolute;
				right: 0;
			}
		}

		.ant-drawer-footer {
			.footerIcons {
				display: flex;
				align-items: center;
				justify-content: space-around;

				.deleteFooter {
					text-align: center;
					.transition();

					span {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;
						gap: 10px;
						cursor: pointer;
						font-size: 14px;
						font-weight: 600;
						font-family: @primary-font;
						color: @grey-color;
					}

					&:hover {
						svg {
							path {
								fill: @colorPink;
							}
						}

						span {
							color: @colorPink;
						}
					}
				}

				.archiveFooter {
					.transition();

					&:hover {
						svg {
							path {
								fill: @primary-color;
							}
						}

						span {
							color: @primary-color;
						}
					}

				}
			}


		}

		.listCategory {
			margin-top: 20px;

			.ant-list {
				margin-top: 10px;
			}

			.searchName {
				.ant-input {
					background-color: @lighgrey-color;
					border: none;
					height: 50px;
					border-top-left-radius: 12px;
					border-bottom-left-radius: 12px;
					font-weight: 600;
				}

				.ant-input-group-addon {
					border-top-right-radius: 12px;
					border-bottom-right-radius: 12px;
					// border: 1px solid;


				}

				.ant-btn {
					border-top-right-radius: 12px;
					border-bottom-right-radius: 12px;
					height: 48px;
					background-color: @lighgrey-color;
					box-shadow: none;
				}
			}

			// .ant-input-wrapper {
			// 	&:focus {
			// 		box-shadow: 0 0 0 1px #4a5cfb;
			// 		border-radius: 12px;
			// 	}
			// }
			.ant-list-split {
				.ant-spin-container {
					text-align: center;
				}
			}

		}

		.ant-drawer-header {
			.ant-drawer-header-title {
				.ant-drawer-title {
					font-family: @secondary-font;
					font-weight: 400;
					font-size: 15px;
					color: @grey-color;
				}
			}
		}

		.ant-drawer-body {
			padding: 20px 24px 24px 24px;

		}


	}

	.dashboardHeader {
		.ant-tabs {
			.ant-tabs-tab {
				.ant-tabs-tab-btn {
					&:hover {
						color: @primary-color;
					}
				}
			}
		}
	}

	@media screen and(max-width:1560px) {
		.childTabWrap {
			.childLeft {
				.dateRange {
					padding: 4px 8px;
					margin: 0 10px;

					.rangeAlignment {
						.week {
							small {
								padding: 0px 3px;
							}
						}

						.start {
							padding-left: 3px;
						}
					}
				}
			}

			.childRight {
				.ant-segmented {
					.ant-segmented-item {
						padding: 4px 5px;

						.ant-segmented-item-label {
							padding: 0 2px;

							span {
								font-size: 12px;
								flex-wrap: nowrap;
							}
						}
					}
				}
			}

			.dataSwitch {
				.ant-switch {
					height: 22px;

					.ant-switch-handle {
						width: 15px;
						height: 15px;
					}
				}
			}
		}

		.groupWrap {
			.publish-btn {
				padding: 4px 12px;
				height: auto;
				margin-right: 5px;
				font-size: 14px;
			}

			small {
				margin-right: 5px;
			}
		}

	}

	@media screen and(max-width:1340px) {
		.childTabWrap {
			.childLeft {
				width: 40%
			}

			.childRight {
				width: 60%;
				justify-content: flex-end;
			}
		}
	}

	@media screen and(max-width:1150px) {
		.childTabWrap {
			flex-wrap: wrap;
		}
	}
}

// .ant-tabs-tab-btn{
// 	border-radius: 14px 14px 0px 0px;
// 	background-color: #e2e5ee;
// }
.linkTab {
	.ant-tabs-tab {
		background-color: transparent !important;
	}

	.ant-tabs-tab-remove {
		// padding-left: 11px;
		margin-left: 11px;
	}
}

.ant-tabs-tab-with-remove {
	background-color: #e2e5ee !important;
	border-radius: 14px 14px 0px 0px !important;
	border: none !important;
}

.ant-tabs-tab-active {
	background-color: #fff !important;
	border-radius: 14px 14px 0px 0px !important;
	border: none !important;
}

.ant-tabs-tab-remove {
	margin-right: 7px;
	margin-left: -10px;
	margin-top: 4px;
	background-color: @body-bg;
	margin-top: 0px;
	border-radius: 50%;
	height: 13px;
	width: 13px;
	font-size: 8px;
	.transition();

	.anticon {
		margin: -4px !important;
		.transition();

		svg {
			path {
				fill: @grey-color;
			}
		}
	}

	&:hover {
		background-color: @primary-color;

		.anticon {
			svg {
				path {
					fill: #fff;
				}
			}
		}
	}
}

// Pratik Sangani

.userNode {
	width: 300px;
	padding: 5px;
	box-shadow: none;
	border: 1px dashed @greyborder-color;
	border-radius: 15px;
	background-color: transparent;
	text-align: left;
}

.userInfo {
	background-color: @white-color;
	box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
	border-radius: 15px;
	padding: 20px 20px 20px 70px;
	position: relative;
	margin-bottom: 5px;
	word-break: break-word;

	.thumb {
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
		width: 40px;

		img {
			max-width: 100%;
			border-radius: 50%;
		}
	}

	h2 {
		font-size: 16px;
		font-weight: 500;
		color: @default-color;
		margin: 0;
		text-transform: none;
		font-family: @secondary-font;
		line-height: 1.2;
	}

	h4 {
		font-size: 14px;
		font-weight: 500;
		color: @grey-color;
		margin: 0;
		text-transform: none;
		font-family: @secondary-font;
		line-height: 1.2;
	}
}

.userNode {
	h3 {
		font-size: 14px;
		font-weight: 700;
		color: @grey-color;
		margin: 0;
		text-transform: none;
		font-family: @secondary-font;
		line-height: 1.2;
		width: 100%;
		text-align: center;
		padding: 14px;
		border-bottom: 1px solid @altralight-color;
	}

	h6 {
		font-size: 12px;
		font-weight: 400;
		color: @grey-color;
		font-family: @secondary-font;
		margin-bottom: 8px;
		text-align: center;
	}

	#skill {
		display: flex;
		flex-wrap: wrap;
		background-color: @white-color;
		box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
		border-radius: 15px;
		// Pratik Sangani
		margin: 5px;

		#skills {
			width: 50%;
			padding: 10px;
			border-right: 1px solid @altralight-color;

			&:last-child {
				border-right: none;
			}

			ul {
				margin: 0 0 0 16px;
				padding: 0;

				li {
					font-family: @secondary-font;
					font-weight: 400;
					font-size: 12px;
					// word-break: break-all;
					word-break: break-word;
				}
			}
		}
	}
}

.mapHeading {
	border: none;
	min-width: 200px;
	min-height: 100px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	// background-color: @white-color;
	border-radius: 15px;

	&.selected {
		border: none;
		box-shadow: none;
	}
}

.textBox {
	border: none;
	min-width: 200px;
	min-height: 100px;
	width: 200px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	background-color: @white-color;
	border-radius: 15px;
}

.datePopover {
	.ant-popover-content {
		.ant-popover-arrow {
			display: none;
		}

		.ant-popover-inner {
			padding: 0px;
			height: 250px;
			max-height: 275px;
			min-width: 450px;
			width: 450px;
		}


		.ant-tabs {
			.ant-tabs-nav-list {
				margin: 10px 0px;

				.ant-tabs-tab {
					margin: 10px 0px;
				}
			}

			.ant-tabs-content-holder {
				border-left: none;
				display: flex;
				height: 250px;


				.ant-form-item {
					margin: 20px 24px 0px 24px;
					position: relative;


					.ant-form-item-label {
						padding: 0;
						position: absolute;
						left: 16px;
						top: 2px;
						z-index: 9;
						.transition;

						label {
							font-size: 12px;
							color: @grey-color;
							font-weight: 400;
							font-family: @primary-font;
							height: 100%;
						}

					}

					.ant-select-selector {
						height: 50px;
						font-size: 14px;
						color: @default-color;
						font-weight: 700;
						font-family: @primary-font;
						height: 50px;
						border: none;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						background-color: @lighgrey-color;
						padding: 12px 15px 0px 15px;
						border-radius: 12px;

						.ant-select-selection-item {
							font-weight: 700;
						}
					}
				}
			}

			.ant-tabs-tabpane {
				padding-left: 0px;
				// flex-direction:column;
				// justify-content: space-between;
				// display: flex;
			}

			.ant-tabs-ink-bar {
				position: absolute;
				left: 0;
				border-top-right-radius: 17px;
				border-bottom-right-radius: 17px;
			}


			.ant-tabs-nav {
				position: relative;

				// margin: 10px 0px;
				&:after {
					position: absolute;
					background-color: #E5E7EC;
					content: "";
					width: 1px;
					height: 100%;
					right: -7px
				}

				.ant-tabs-tab-btn {
					.mediumFont();
					font-size: 14px;
				}

				.ant-tabs-tab {
					padding: 0px 12px 0px 16px !important;
				}

			}

			.bottomContent {
				width: 100%;
				display: flex;
				align-items: center;
				// padding:0px 24px;
				justify-content: space-between;
				position: absolute;
				bottom: 0;

				.dateRangeBtn {
					width: 100%;
					display: flex;
					align-items: center;
					position: relative;
					cursor: pointer;
					margin-bottom: 20px;

					.cancelBtn {
						width: 50%;
						height: 42px;
						background-color: rgba(74, 92, 251, 0.1);
						margin: 0px 10px 0px 24px;
						color: @grey-color;
						cursor: pointer;
						.transition();

						&:hover {
							background: #4a5cfb33;
						}
					}

					.setBtn {
						width: 50%;
						height: 42px;
						margin-right: 24px;
					}

					&::before {
						position: absolute;
						content: "";
						background-color: #E5E7EC;
						width: 100%;
						height: 1px;
						top: -20px;
						left: 7px;
					}

				}
			}
		}
	}
}



// Pratik Sangani

.colorGreen {
	background-color: #dff1e6;

	.ant-table-cell-row-hover {
		background-color: #a6d3b7;
	}
}

.colorPink {
	background-color: #f4e6e6;
}

.colorOrange {
	background-color: #f5f1e0;
}

// grapview changes
.containerContent {
	width: 100%;
	// height: 76vh;
	height: 100%;
	max-height: 100%;
	overflow: auto;

	.GraphDragDrop {
		top: 15px !important;
		left: 10px !important;
	}

	.btnLayout {
		position: absolute;
		right: 0;
		top: 15px;
		z-index: 9;
		padding-right: 20px;
		display: flex;
		flex-wrap: wrap;




		.backPrevBtn {
			background-color: #fff;
			max-width: 120px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 5px 12px;
			border-radius: 10px;
			margin-right: 10px;
		}

		.archiveBtn {
			button {
				padding: 8px 12px;
				display: flex;
				align-items: center;
				border: none;
				background: #fff;
				border-radius: 10px;
				max-width: 100px;
				width: 100px;
				justify-content: space-around;
				cursor: pointer;
				.transition();

				span {
					font-family: @primary-font;
					font-size: 14px;
					font-weight: 600;
					color: @grey-color;


				}

				svg {
					margin-right: 5px;
				}

				&:hover {
					svg {
						path {
							fill: @primary-color;
						}
					}

					span {
						color: @primary-color;
					}
				}
			}
		}
	}
}