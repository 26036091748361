@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../styles/fonts/GTWalsheimPro-Bold.eot');
  src: url('../styles/fonts/GTWalsheimPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../styles/fonts/GTWalsheimPro-Bold.woff2') format('woff2'),
      url('../styles/fonts/GTWalsheimPro-Bold.woff') format('woff'),
      url('../styles/fonts/GTWalsheimPro-Bold.ttf') format('truetype'),
      url('../styles/fonts/GTWalsheimPro-Bold.svg#GTWalsheimPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../styles/fonts/GTWalsheimPro-Regular.eot');
  src: url('../styles/fonts/GTWalsheimPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../styles/fonts/GTWalsheimPro-Regular.woff2') format('woff2'),
      url('../styles/fonts/GTWalsheimPro-Regular.woff') format('woff'),
      url('../styles/fonts/GTWalsheimPro-Regular.ttf') format('truetype'),
      url('../styles/fonts/GTWalsheimPro-Regular.svg#GTWalsheimPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../styles/fonts/GTWalsheimPro-Light.eot');
  src: url('../styles/fonts/GTWalsheimPro-Light.eot?#iefix') format('embedded-opentype'),
      url('../styles/fonts/GTWalsheimPro-Light.woff2') format('woff2'),
      url('../styles/fonts/GTWalsheimPro-Light.woff') format('woff'),
      url('../styles/fonts/GTWalsheimPro-Light.ttf') format('truetype'),
      url('../styles/fonts/GTWalsheimPro-Light.svg#GTWalsheimPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../styles/fonts/GTWalsheimPro-Medium.eot');
  src: url('../styles/fonts/GTWalsheimPro-Medium.eot?#iefix') format('embedded-opentype'),
      url('../styles/fonts/GTWalsheimPro-Medium.woff2') format('woff2'),
      url('../styles/fonts/GTWalsheimPro-Medium.woff') format('woff'),
      url('../styles/fonts/GTWalsheimPro-Medium.ttf') format('truetype'),
      url('../styles/fonts/GTWalsheimPro-Medium.svg#GTWalsheimPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

