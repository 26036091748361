.main__page__appheader {
	display: flex;
	align-items: center;
	.container-fluid {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.appheader__left {
		display: flex;
	}
	.sidebar__trigger {
		cursor: pointer;
	}
}
