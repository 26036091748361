// @import (css)
// 	url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:wght@500&family=Roboto:wght@500&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:wght@500&family=Roboto:wght@500&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

// Theme Variables
@import "~antd/lib/style/themes/default.less";
@import "variables.less";
@import "fonts.less";

// Third Party Stylesheets
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry

// Common and Overrides Stylesheets
@import "./utils/utilities.less";
@import "./common.less";
@import "./override.less";

// Layout Stylesheets  
@import "./layout/header.less";
@import "./layout/app-header.less";
@import "./layout/app-sidebar.less";

// Utils Stylesheets
@import "./utils/container.less";

// Sections Stylesheets
@import "./sections/hero-section.less";

// Views Stylesheets
@import "./views/auth.less";

// Templates stylesheets
@import "./templates/setting-page.less";
@import "./templates/dashboard.less";
@import "./templates/team-page.less";
@import "./templates/archive-section.less";
@import "./templates/graph-view.less";
@import "_formulize.less";

@import 'reactflow/dist/style.css';
// @import 'reactflow/dist/base.css';