.dashboardSidebar {
	height: 100vh;
	background: @layout-body-background;
	border-right: 1px solid @altralight-color;

	.logo__wrapper {
		padding: 20px 10px;
		border-bottom: 1px solid @altralight-color;
		text-align: center;

		a {
			margin: 0 auto;

			img {
				display: block;
				margin: 0 auto;
			}
		}
	}

	.userLogo {
		text-align: center;
		margin: 25px auto;
		max-width: 140px;

		//  height: 60px;
		img {
			max-width: 100%;
			max-height: 100%;
		}

		.link {
			height: 0;
			.transition;
			overflow: hidden;
			a {
				font-size: 12px;
				font-weight: 400;
				font-family: @primary-font;
				color: rgba(0, 0, 0, 0.5);
				margin-bottom: 0;
        &:hover {
		       text-decoration: underline;
		        }
	          }
             }
          &:hover {
               .link {
                height: 20px;
	            }
		}
	}

	.userInfoDetailsWrap {
		padding: 15px;
		margin-top: 10px;
		display: inline-block;
		width: 100%;

		.userInfoDetails {
			width: 100%;
			background-color: @lighgrey-color;
			border-radius: 12px;
			margin-bottom: 12px;
			position: relative;

			.userLogo {
				text-align: center;
				margin: 10px 0;

			}

			.userNotification {
				position: absolute;
				right: 12px;
				top: 12px;

				&::after {
					content: "";
					position: absolute;
					right: 0;
					top: 0;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: @pink-color;
				}
			}
		}

		.userPic {
			text-align: center;
			margin-bottom: 0;
			padding: 0 15px;

			figure {
				margin-bottom: 5px;
				margin-top: -30px;
				display: inline-block;

				img {
					margin: 0 auto;
					display: block;
					border-radius: 50%;
					max-width: 60px;
				}
			}

			h2 {
				font-size: 15px;
				font-weight: 700;
				font-family: @primary-font;
				color: @black-color;
				margin-bottom: 0;
				word-wrap: break-word;
			}
		}

		.btnWrap {
			padding-bottom: 15px;

			button {
				min-width: 70px;
			}
		}
	}

	.menuAccordion {
		&.ant-collapse {
			background-color: transparent;
			padding: 8px 12px;
			border: none;

			>.ant-collapse-item {
				border-bottom: 1px solid @altralight-color;

				.ant-collapse-header {
					padding: 0;
					font-size: 12px;
					color: @black-color;
					font-family: @primary-font;
					font-weight: 400;
					padding: 8px 0;
					padding-right: 40px;

					.ant-collapse-arrow {
						right: 0;
						font-size: 10px;
						color: @primary-color;
						font-weight: 700;
					}
				}

				.ant-collapse-content {
					border: none;
					background-color: transparent;

					.ant-collapse-content-box {
						padding: 0;
					}
				}

				&:last-child {
					border-bottom: none;
				}

				ul {
					&.submenuList {
						margin: 0 0 5px 0;
						padding: 0;

						li {
							list-style: none;
							font-size: 12px;
							color: rgba(0, 0, 0, 0.5);
							font-family: @primary-font;
							font-weight: 400;
							padding: 3px 0;
							position: relative;
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							align-items: center;

							span {
								font-weight: 600;
								opacity: 1;
								color: #222325;
							}
						}
					}
				}
			}
		}
	}

	.refferedBlock {
		// background-color: @lighgrey-color;
		// border-radius: 12px;
		padding: 8px 0px;

		h3 {
			font-size: 12px;
			font-weight: 400;
			font-family: @primary-font;
			color: rgba(0, 0, 0, 0.5);
			margin-bottom: 10px;
		}

		ul {
			margin: 0 0 10px;
			padding: 0;
			display: flex;
			flex-wrap: wrap;

			li {
				list-style: none;
				font-size: 12px;
				text-align: center;
				color: #222325;
				position: relative;
				width: 33%;

				span {
					display: block;
					font-size: 20px;
					font-weight: 700;
					color: #222325;
					font-family: @primary-font;
				}

				&::after {
					content: "";
					position: absolute;
					right: 0;
					top: 0;
					width: 1px;
					height: 100%;
					background-color: @altralight-color;
				}

				&:last-child {
					&::after {
						display: none;
					}
				}
			}
		}

		button {
			width: 100%;
		}

		.commanButton {
			// background-color: #fff;
			background-color: @lighgrey-color;

			a {
				font-family: @primary-font;
				font-size: 12px;
				font-weight: 600;
				display: flex;
				align-items: center;
				color:#000;

			}

			&:hover {
				background-color: @primary-color;

				a {
					color: #fff;

					svg {
						path {
							stroke: #fff;
						}
					}
				}

			}
		}
	}

	.projectsListing {
		width: 100%;
		border-top: 1px solid @altralight-color;
		border-bottom: 1px solid @altralight-color;
		padding: 15px 0;
		margin-bottom: 10px;

		.projectTitle {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			font-size: 12px;
			font-family: @primary-font;
			font-weight: 400;
			margin-bottom: 15px;
			padding: 0 15px;

			>span {
				color: rgba(0, 0, 0, 0.5);
			}

			.anticon {
				color: @primary-color;
				font-size: 14px;
				font-weight: 500;
			}
		}

		ul {
			&.listing {
				margin: 0;
				padding: 0;

				li {
					list-style: none;
					position: relative;

					a {
						color: @grey-color;
						font-size: 14px;
						word-break: break-all;
						font-weight: 600;
						font-family: @primary-font;
						padding: 10px 40px 10px 30px;
						display: inline-block;
						line-height: 1.2;
						width: 100%;
						.transition;

						svg {
							opacity: 0;
						}
					}

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 50%;
						width: 3px;
						height: 0;
						background-color: @primary-color;
						border-radius: 0px 10px 10px 0;
						transform: translateY(-50%);
						.transition;
					}

					&::after {
						content: "";
						position: absolute;
						left: 10px;
						top: 50%;
						transform: translateY(-50%);
						width: 8px;
						height: 8px;
						border-radius: 50%;
						background-color: #fb9f4a;
					}

					&:nth-child(2) {
						&::after {
							background-color: #aad880;
						}
					}

					&:nth-child(3) {
						&::after {
							background-color: #4adbfb;
						}
					}

					&:hover,
					&.active {
						a {
							background-color: rgba(74, 92, 251, 0.05);
							color: #222325;
						}

						&::before {
							height: 100%;
						}

						svg {
							opacity: 1;

							path {
								fill: @primary-color;
							}
						}

					}
				}

				li {
					display: flex;
					align-items: center;

					svg {
						position: absolute;
						right: 17px;
					}
				}

				.projectButton {
					background: transparent !important;
					box-shadow: none !important;

					&:hover {
						background-color: transparent;
					}

					padding:7px 0px;
				}
			}
		}

		.sideBarScroll>div {
			overflow-x: hidden !important;
		}
	}

	.sidebarNavigation {
		width: 100%;

		.ant-menu {
			border: none;

			.ant-menu-item {
				height: auto;
				padding: 0 15px !important;
				margin: 0;

				svg {
					path {
						.transition;
					}
				}

				a {
					color: @grey-color;
					font-size: 14px;
					font-weight: 600;
					font-family: @primary-font;
				}

				&:hover {
					a {
						color: @primary-color;
					}

					svg {
						path {
							fill: @primary-color;
						}
					}
				}

				&:after {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					border-left: 3px solid #4a5cfb;
					border-right:none;
				}
			}
		}
	}
	&.ant-layout-sider-collapsed{
		flex: 0 0 0px !important;
		max-width: 0px !important;
		min-width: 0px !important;
		width: 0px !important;
	}

}

.ant-popover {
	.ant-popover-inner {
		padding: 12px;
		box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		max-width: 500px;

		.ant-popover-title {
			font-size: 16px;
			font-weight: 600;
			font-family: @primary-font;
			color: @default-color;
			padding: 15px 10px;
			border-bottom: none;
		}

		.ant-popover-inner-content {
			padding: 0;
		}
	}

	.notificationListing {
		.closebtn {
			position: absolute;
			right: 20px;
			top: 28px;
			font-size: 14px;
			font-family: @primary-font;
			color: @grey-color;
			font-weight: 600;

			span {
				margin-left: 5px;
				font-weight: 700;
			}
		}

		.itemList {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			font-family: @secondary-font;
			font-weight: 400;
			font-size: 14px;
			padding: 15px;
			background-color: @white-color;
			border-radius: 8px;
			.transition;
			margin-bottom: 10px;

			.thumb {
				width: 48px;

				img {
					max-width: 100%;
					border-radius: 50%;
					border: 2px solid @white;
				}
			}

			.content {
				width: calc(100% - 120px);

				a {
					font-family: @secondary-font;
					font-weight: 500;
					color: @primary-color;
					text-decoration: underline;
				}
			}

			.info {
				color: @grey-color;

				small {
					display: block;
					font-size: 12px;
				}

				span {
					font-size: 12px;
					opacity: 0;
					visibility: hidden;
					.transition
				}
			}

			p {
				margin-bottom: 0;

				strong {
					font-weight: 500;
				}
			}

			&:hover {
				background-color: @lighgrey-color;

				.info {
					span {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}

.logoListing {
	padding: 0;
	text-align: center;

	ul {
		margin: 0;
		padding: 0;

		li {
			list-style: none;
			background-color: @altralight-color;
			padding: 10px;
			border-radius: 10px;
			margin: 10px 0;

			svg,
			img {
				margin: 0 auto;
				max-width: 40px;
			}
		}
	}
}