.settingSection {
	width: 100%;

	h1 {
		margin-bottom: 20px;
	}
}

.vertiverticalTab {
	.ant-tabs-tabpane {
		padding: 0 !important;
	}

	.ant-tabs-nav {
		.ant-tabs-nav-wrap {
			background-color: @white;
			box-shadow: 0px 4px 40px rgba(40, 43, 54, 0.08);
			border-radius: 10px;
			padding: 18px 0;
			flex: none;
			min-width: 280px;
		}

		.ant-tabs-nav-list {
			.ant-tabs-tab {
				font-size: 16px;
				font-weight: 600;
				color: @grey-color;
				font-family: @primary-font;
				margin: 0;
				padding: 10px 20px;

				&.ant-tabs-tab-active {
					color: @primary-color;
				}
			}

			.ant-tabs-ink-bar {
				left: 0;
				right: auto;
				width: 3px;
				border-radius: 0 10px 10px 0;
			}
		}
	}

	.ant-tabs-content-holder {
		margin: 0;
		border: none;
		margin-left: 25px;
		max-width: 800px;

		.tabFormWrap {
			background-color: @white-color;
			padding: 20px;
			border-radius: 10px;
			margin-bottom: 20px;
			.ant-input{
				padding: 20px 20px 5px 16px !important;
			}
			.ant-form-item {
				margin-bottom: 10px;
			}

			.companyLogo {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;

				.thumb {
					width: 100px;
					height: 100px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					background-color: @lighgrey-color;
					border-radius: 50%;

					img {
						max-width: 100px;
					}

					&.fullthumb {
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 50%;
							max-width: 100%;
						}
					}
				}

				.content {
					width: calc(100% - 120px);
				}

				.uploadSelect {
					margin-bottom: 10px;
					display: flex;
					flex-wrap: wrap;
					align-items: baseline;

					.ant-upload {
						button {
							margin-right: 36px;

							&:after {
								content: "";
								position: absolute;
								right: -20px;
								top: 7px;
								width: 6px;
								height: 6px;
								border-radius: 50%;
								background-color: #babecb;
								display: block;
							}
						}
					}

					button {
						background-color: transparent;
						padding: 0;
						border: none;
						box-shadow: none;
						height: auto;
						font-size: 16px;
						font-weight: 600;
						text-decoration: underline;
						position: relative;
						line-height: 1.2;

						&:hover,
						&:focus {
							color: @secondary-color;
						}
					}

				}
			}

			.OrganizationList {
				.item {
					width: 100%;
					margin-bottom: 10px;
					background-color: @lighgrey-color;
					border-radius: 12px;
					padding: 10px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: space-between;
					gap: 20px;

					.iconWrap {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						gap: 15px;
						word-break: break-word;

						.ant-image {
							max-width: 40px;

							img {
								border-radius: 50%;
							}
						}

						span {
							font-size: 16px;
							color: @grey-color;
						}
					}

					.memberModelLink {
						 button{
							background: #4a5cfb33;
							&:hover{
								background-color: #001aff1a;
								color:@primary-color;
							}

						 }
						 >span {
							color: @grey-color;
							font-size: 16px;
							font-weight: 600;
						}
					}
				}
			}

			.PhoneInput {
				position: relative;
				height: 50px;
				border: none;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				background-color: @lighgrey-color;
				padding: 0 15px;
				.transition;
				border-radius: 12px;

				.PhoneInputCountry {
					margin-right: 15px;

					.PhoneInputCountryIcon--border {
						border: none;
						box-shadow: none;
						background-color: transparent;

						.PhoneInputCountryIconImg {
							border-radius: 3px;
						}
					}

					.PhoneInputCountrySelectArrow {
						border-color: @primary-color;
						width: 8px;
						height: 8px;
						border-bottom-width: 1px;
						border-right-width: 1px;
						opacity: 1;
						margin-top: -3px;
						margin-left: 12px;
					}
				}

				.PhoneInputInput {
					font-size: 14px;
					color: @default-color;
					font-weight: 600;
					font-family: @primary-font;
					background-color: transparent !important;
					border: none !important;
					box-shadow: none !important;
					outline: none !important;
				}
			}

			.btn-primary {
				margin: 0;
				font-size: 16px;
				font-weight: 600;
				color: @primary-color;
				background-color: rgba(74, 92, 251, 0.1);
				height: 50px;
				width: 100%;
				box-shadow: none;

				&:hover,
				&:focus {
					// background-color: @primary-color;
					background: #4a5cfb33;
					// color: @white-color;
				}
				&.btn-blue{
					background-color: @primary-color;
					color: @white-color;

					&:hover {
						background-color: rgba(74, 92, 251, 0.8);
						color: @white-color;
					}
				}
			}


			.ant-input-group-addon {
				background-color: transparent;
				border: none;
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 999;

				.ant-btn {
					background-color: transparent;
					box-shadow: none;
					padding: 0 15px 0 0;
					width: auto;

					.anticon {
						font-size: 20px;
						font-weight: 700;
						color: @primary-color;
					}
				}
			}

			.mobileLabel {
				position: absolute;
				top: 5px;
				z-index: 9;
				left: 16px;
				font-size:12px;
			}

			.PhoneInput {
				padding: 20px 20px 5px 16px;
			}
			.integrationsCheck{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
				gap: 20px;
				margin-bottom: 20px;
				span{
					font-size: 20px;
				}
			}
		}

		.reminderList {
			background-color: @white-color;
			padding: 27px 20px 27px 20px;
			border-radius: 10px;
			margin-bottom: 10px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			gap: 20px;

			span {
				font-size: 20px;
				color: @black-color;
			}
		}
	}

	.profilePasswordField {
		.ant-input-affix-wrapper {
			border: none;
			padding: 0;
			border-radius: 12px;

			&:focus {
				box-shadow: none;
			}

			.ant-input-suffix {
				position: absolute;
				right: 15px;
				top: 18px;
			}
		}

		// 	.ant-form-item-label {
		// 		left: 30px;
		// 		top: 10px;
		// }
		.ant-input-affix-wrapper-focused {
			box-shadow: 0 0 0 1px #4a5cfb !important;
		}
	}
}
