.archiveSection {
    .archiveHeading {
        font-size: 24px;
        color: @black-color;
    }

    .ArchiveBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            cursor: pointer;
            line-height: 55px;

            a {
                font-family: @primary-font;
                font-size: 14px;
                font-weight: 600;
                color: @primary-color;


            }
        }
    }

    .archiveLayout {
        .ag-theme-alpine {
            .ag-row {
                border: none;
            }
        }

        .ag-root-wrapper {
            border-radius: 10px;
        }
    }

    .ag-header-cell-comp-wrapper {
        .ag-header-cell-label {
            .ag-header-cell-text {
                font-family: @secondary-font;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
}

.archiveModal {
    .ant-modal-body {
        padding: 24px 24px 0px 24px;

        .deleteContent {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;

            span {
                font-family: @primary-font;
                font-weight: 400;
                font-size: 16px;
                text-align: center;
            }

            .deleteSign {
                margin-bottom: 10px;

                img {
                    width: 34px;
                    height: 34px;
                }
            }
        }
    }

    .ant-modal-footer {
        padding: 20px 24px 10px 24px ;

        .cancelBtn {
            background-color: transparent;
            color:@primary-color;
            box-shadow:none;
        }

    }
}

// --restore--
.restoreContainer{
    padding:0px 0px !important;
.restoreLayout{
	position: relative;
	background-color: #fff;
	padding:14px 24px;
	display: flex;
    align-items: center;
    justify-content: space-between;
	 .restoreHeading{
		display: flex;
		align-items: center;
		position: relative;

		
		.restoreHeader{
			padding-left:20px;
			h1{
				margin-bottom:5px;
			}
			p{
				margin-bottom:0px;
			}
		}
		 .backTo{
			height: 24px;
			width: 24px;
			position: absolute;
			left: -10px;
			top: 2px;
			border-radius: 50%;
			background: rgba(74, 92, 251, 0.1);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		 }
	 }
	 .ant-segmented {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		background-color: @lighgrey-color;
		padding: 3px;
		border-radius: 12px;

		.ant-segmented-item {
			// padding: 8px 10px;
			padding: 4px 10px;
			background-color: transparent;
			font-size: 14px;
			font-family: @primary-font;
			color: @grey-color;
			height: auto;
			border-radius: 10px !important;
			box-shadow: none;
			font-weight: 600;

			span {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			svg {
				margin-right: 10px;
			}

			&.ant-segmented-item-selected {
				background-color: #fff;
				// color: @default-color;
				color: @primary-color;
				border-radius: 10px !important;

				svg {
					rect {
						fill: @primary-color;
					}

					path {
						fill: @primary-color;
					}
				}


			}

			&:hover {
				color: @primary-color;

				svg {
					rect {
						fill: @primary-color;
					}

					path {
						fill: @primary-color;
					}
				}
			}
		}
	}


}
}

.restoreModal{
    .ant-modal-body{
        padding:24px 24px 0px 24px;
        .deleteContent{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;
            span{
                font-family:@primary-font;
                font-size:16px;
                font-weight: 400;
            }
        }
    }
    .ant-modal-footer{
        padding:24px 24px 10px 24px;
        .cancelBtn {
            background-color: transparent;
            color:@primary-color;
            box-shadow:none;
        }
    }
}