@primary-color: #4a5cfb;
@secondary-color: #fbaa4a;
@lighgrey-color: #f2f3f7;
@altralighgrey-color: #c9ccda;
@border-bottom-after:#eeedf0;
@black-color: #000000;
@white-color: #ffffff;
@grey-color: #676a78;
@greyborder-color: #bdc1cd;
@Violetgrey-color: #e2e5ee;
@altralight-color: #e5e7ec;
@altralightgrey-color: #f9f9fc;
@pink-color: #ff6c6c;
@lightPink-color: #f4e6e6;
@lightgreen-color: #dff1e6;
@lightyellow-color: #f5f1e0;
@default-color: #222325;
@body-bg: #f1f2f6;
@orange-color: #ec9f58;
@lightOrange: rgba(236, 159, 88, 0.1);

@altralightgreen-color: #AAD880;
@green-color: #5BAF3D;

@colorPink: #D88080;
@lightPinkColor: #FDCFCF;
// @colorOrange: #ec9f58;
@colorOrange: #FFCC00;
// @lightOrangeColor: #F5F1E0;
@lightOrangeColor: #ffcc004f;
@colorGreen: #AAD880;
@lightGreenColor: #BFF0D3;

@gutter: 24px;
@primary-font: "Source Sans Pro", sans-serif;
@secondary-font: "GT Walsheim Pro", sans-serif;
@text-color: #494949;
@heading-color: @text-color;
@border-radius-base: 4px;

// Main Header Variables
@layout-body-background: @white;
@layout-content-background: #f0f2f5;
@layout-header-background: @white;
@layout-header-height: 64px;
@layout-header-padding: 0 0;
@layout-header-color: @text-color;
@layout-sidebar-background: #263238;

// Main Header Logo Variables
@logo-height: 42px;
@logo-text-size: 18px;
@logo-space: 10px;

// Section Variables
@section-padding: 50px;

.transition {
	transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
}

// // font family, font-weight:600
.mediumFont{
    font-family:@primary-font;
    font-weight:600;
}

// grid font------------
.semiMediumFont{
    font-family:@primary-font;
    font-weight:400;
}


// Media Query mixin --


