.containerWrap {
	width: 100%;
	height: 97vh;
	position: relative;
	overflow: auto;
}

.chartContainer {
	// margin: 61px 240px;
	width: 100%;

	float: right;
	position: relative;
	// margin-right: 102px;

	.adSpendContainer {
		.UserProfile {
			svg {
				height: 30px;
				width: 30px;
			}
		}
	}

	.ant-card {
		width: auto;
		// min-height:480px;
		// max-width: 100%;
		border-radius: 14px;
		margin-bottom: 30px;
		position: relative;
		cursor: pointer;
		height: 100%;
		overflow-x:auto;
		overflow-y: auto;

		.ant-card-body {
			padding: 18px 20px 0px 20px;

			.cardHeader {
				display: flex;
				align-items: center;
				justify-content: space-between;

				h4 {
					margin-bottom: 0px;
					.semiMediumFont();
					font-size: 16px;
					text-align: left;
				}

				svg {
					.transition();

					&:hover {
						path {
							fill: @primary-color;
						}
					}
				}

				.profileContent {
					display: flex;
					width: 100%;
					align-items: center;

					svg {
						margin-left: 19px;
						height: 26px;
						width: 26px;
					}
				}

				.headerIcon {
					margin-top: 7px;
					display: flex;
					align-items: center;

					a {
						margin-right: 5px;
						cursor: pointer;
					}
				}
			}

			.cardContent {
				.popoverLink {
					.mediumFont();
					font-size: 14px;
					color: @primary-color;
					width: 50%;

					svg {
						margin-left: 7px;
						width: 9px;
					}
				}

				.horizontalLink {
					.semiMediumFont();
					font-size: 12px;
					color: @grey-color;
					margin-bottom: 6px;
				}

				.sessionHeader {
					h3 {
						.semiMediumFont();
						font-size: 22px;
						margin-bottom: 0px;
						text-align: center;
					}

					.updownRatio {
						display: flex;
						align-items: center;
						justify-content: center;
						// margin-top: 8px;

						h2 {
							.mediumFont();
							margin-bottom: 0px;
							font-size: 30px;
						}

						.greenBg {
							background: rgba(91, 175, 61, 0.1);
							border-radius: 50px;
							padding: 5px 14px;
							margin-left: 12px;

							.numberUp {
								margin-left: 8px;
								.mediumFont();
								font-size: 14px;
								color: @green-color;
							}
						}

						.redBg {
							background: rgba(216, 128, 128, 0.1);
							border-radius: 50px;
							padding: 5px 14px;
							margin-left: 12px;

							.numberUp {
								margin-left: 8px;
								.mediumFont();
								font-size: 14px;
								color: @pink-color;
							}
						}
					
					}

					.prevData {
						text-align: center;
						// margin-top: 6px;

						span {
							.semiMediumFont();
							font-size: 14px;
							color: @grey-color;
						}
					}
				}

				.ant-table-wrapper {
					.ant-table {
						.ant-table-thead {
							.ant-table-cell {
								padding: 0px 0px 15px 0px;
								background-color: @white;

								.ant-table-column-sorters {
									display: block !important;

									.ant-table-column-title {
										.mediumFont();
										font-size: 16px;
										color: @grey-color;
									}

									.ant-table-column-sorter-full {
										vertical-align: middle;

										.ant-table-column-sorter-inner {
											vertical-align: bottom;
										}
									}
								}

								&.centerRow {
									text-align: center;
								}
							}

							&::before {
								display: none;
							}
						}

						.ant-table-tbody {
							.ant-table-cell {
								border-bottom: none;
								padding: 12px 0px 5px 0px;
								.semiMediumFont();
								font-size: 16px;
								color: @default-color;

								&.lastROw {
									text-align: center;
								}

								&.lastRowDiff {
									.statusLine {
										.mediumFont();
										font-size: 14px;
										color: @green-color;

										span {
											margin-left: 2px;
										}

										.ant-badge {
											&.ant-status-badge {
												margin: 0px 8px;
											}
										}

										svg {
											margin: 0px 6px;
										}
									}
								}

								.statusLine {
									.ant-badge {
										&.ant-badge-status {
											.ant-badge-status-dot {
												height: 9px;
												width: 9px;
											}
										}
									}
								}

								&.centerRow {
									text-align: center;
								}
							}

							.currentStatuscol {
								.mediumFont();
								font-size: 16px;
								color: @default-color;

								.ant-badge {
									&.ant-badge-status {
										margin: 0px 8px 0px 0px;

										.ant-badge-status-dot {
											height: 9px;
											width: 9px;
										}
									}
								}

								.ratioRate {
									&.ant-badge-status {
										margin: 0px 8px 0px 0px;

										.ant-badge-status-dot {
											height: 6px;
											width: 6px;
										}
									}
								}
							}

							tr {
								&:hover {
									td {
										background-color: transparent;
									}
								}
							}
						}

						th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
							&::before {
								display: none;
							}
						}
					}
				}

				.ant-divider {
					margin: 10px 0px;
				}
			}
			.goalPer{
				background: rgba(97, 146, 168, 0.1);
				border-radius: 50px;
				padding: 5px 14px;
				margin-left: 12px;

				.numberUp {
					margin-left: 8px;
					.mediumFont();
					font-size: 14px;
					color:@black-color;
				}
			}
			.cardFooter {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 3px -17px;
				cursor: pointer;
			}
		}
	}

	// --graph view auto graph

	.horizontalContainer {
		// min-width: 480px;
		width: auto;
		max-width: 100%;
		height: 100%;

		.ant-card-body {
			height: 100%;

			.cardContent {
				height: calc(100% - 35px);
			}
		}

		#graphContainer {
			width: 100%;
			height: calc(100% - 60px);
			// height: 0;
			// padding-bottom: 45%; /* Set the desired aspect ratio, e.g., 75% for a 4:3 aspect ratio */
			position: relative;
			// min-height: 350px;

			canvas {
				// position: absolute;
				width: 100% !important;
				height: auto !important;
				min-height: min-content;
				max-height: max-content;
			}
		}
	}

	.react-resizable-handle {
		background-image: url("../../../images/resizeIcon.svg") !important;

		&:after {
			display: none;
		}
	}

	.react-grid-layout {
		&.scrollableChart {
			height: 100vh !important;
		}

		.hidden {
			display: none;
		}
	}
}

.chartContainerPadding {
	width: calc(100% - 217px);
	float: right;
	position: relative;
	margin-right: 15px;
	height: 100%;

	.adSpendContainer {
		.UserProfile {
			svg {
				height: 30px;
				width: 30px;
			}
		}
	}

	.ant-card {
		width: auto;
		// min-height:480px;
		// max-width: 100%;
		border-radius: 14px;
		margin-bottom: 30px;
		position: relative;
		cursor: pointer;
		height: 100%;
		overflow-x:auto;
		overflow-y: auto;

		.ant-card-body {
			padding: 18px 20px 0px 20px;

			.cardHeader {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: nowrap;

				h4 {
					margin-bottom: 0px;
					.semiMediumFont();
					font-size: 14px;
					text-align: left;
				}

				svg {
					.transition();

					&:hover {
						path {
							fill: @primary-color;
						}
					}
				}

				.profileContent {
					display: flex;
					width: 40%;
					align-items: center;
					flex-wrap: wrap;

					svg {
						margin-left: 10px;
						height: 24px;
						width: 24px;
					}
				}

				.headerIcon {
					// margin-top: 7px;
					display: flex;
					width: 60%;
					align-items: center;
					flex-wrap: wrap;
					justify-content: flex-end;

					a {
						margin-bottom: 0px;
						.semiMediumFont();
						font-size: 14px;
						text-align: right;
						margin-right: 5px;
						cursor: pointer;

					}

					svg {
						height: 24px;
						width: 24px;
					}
				}
			}

			.cardContent {
				.popoverLink {
					.mediumFont();
					font-size: 14px;
					color: @primary-color;
					width: 25%;

					svg {
						margin-left: 7px;
						width: 9px;
					}
				}

				.horizontalLink {
					.semiMediumFont();
					font-size: 12px;
					color: @grey-color;
					margin-bottom: 6px;
				}

				.sessionHeader {
					h3 {
						.semiMediumFont();
						font-size: 22px;
						margin-bottom: 0px;
						text-align: center;
					}

					.updownRatio {
						display: flex;
						align-items: center;
						justify-content: center;
						// margin-top: 8px;

						h2 {
							.mediumFont();
							margin-bottom: 0px;
							font-size: 30px;
						}

						.greenBg {
							background: rgba(91, 175, 61, 0.1);
							border-radius: 50px;
							padding: 5px 14px;
							margin-left: 12px;

							.numberUp {
								margin-left: 8px;
								.mediumFont();
								font-size: 14px;
								color: @green-color;
							}
						}

						.redBg {
							background: rgba(216, 128, 128, 0.1);
							border-radius: 50px;
							padding: 5px 14px;
							margin-left: 12px;

							.numberUp {
								margin-left: 8px;
								.mediumFont();
								font-size: 14px;
								color: @pink-color;
							}
						}
					}

					.prevData {
						text-align: center;
						// margin-top: 6px;

						span {
							.semiMediumFont();
							font-size: 14px;
							color: @grey-color;
						}
					}
				}

				.ant-table-wrapper {
					.ant-table {
						.ant-table-thead {
							.ant-table-cell {
								padding: 0px 0px 15px 0px;
								background-color: @white;

								.ant-table-column-sorters {
									display: block !important;

									.ant-table-column-title {
										.mediumFont();
										font-size: 16px;
										color: @grey-color;
									}

									.ant-table-column-sorter-full {
										vertical-align: middle;

										.ant-table-column-sorter-inner {
											vertical-align: bottom;
										}
									}
								}

								&.centerRow {
									text-align: center;
								}
							}

							&::before {
								display: none;
							}
						}

						.ant-table-tbody {
							.ant-table-cell {
								border-bottom: none;
								padding: 12px 0px 5px 0px;
								.semiMediumFont();
								font-size: 16px;
								color: @default-color;

								&.lastROw {
									text-align: center;
								}

								&.lastRowDiff {
									.statusLine {
										.mediumFont();
										font-size: 14px;
										color: @green-color;

										span {
											margin-left: 2px;
										}

										.ant-badge {
											&.ant-status-badge {
												margin: 0px 8px;
											}
										}

										svg {
											margin: 0px 6px;
										}
									}
								}

								.statusLine {
									.ant-badge {
										&.ant-badge-status {
											.ant-badge-status-dot {
												height: 9px;
												width: 9px;
											}
										}
									}
								}

								&.centerRow {
									text-align: center;
								}
							}

							.currentStatuscol {
								.mediumFont();
								font-size: 16px;
								color: @default-color;

								.ant-badge {
									&.ant-badge-status {
										margin: 0px 8px 0px 0px;

										.ant-badge-status-dot {
											height: 9px;
											width: 9px;
										}
									}
								}

								.ratioRate {
									&.ant-badge-status {
										margin: 0px 8px 0px 0px;

										.ant-badge-status-dot {
											height: 6px;
											width: 6px;
										}
									}
								}
							}

							tr {
								&:hover {
									td {
										background-color: transparent;
									}
								}
							}
						}

						th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
							&::before {
								display: none;
							}
						}
					}
				}

				.ant-divider {
					margin: 10px 0px;
				}
			}

			.cardFooter {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 3px -17px;
				cursor: pointer;
			}
		}
	}

	// --graph view auto graph

	.horizontalContainer {
		// min-width: 480px;
		width: auto;
		max-width: 100%;
		height: 100%;

		.ant-card-body {
			height: 100%;

			.cardContent {
				height: calc(100% - 35px);
			}
		}

		#graphContainer {
			width: 100%;
			height: calc(100% - 60px);
			// height: 0;
			// padding-bottom: 45%; /* Set the desired aspect ratio, e.g., 75% for a 4:3 aspect ratio */
			position: relative;
			// min-height: 350px;

			canvas {
				// position: absolute;
				width: 100% !important;
				height: auto !important;
				min-height: min-content;
				max-height: max-content;
			}
		}
	}

	.react-resizable-handle {
		background-image: url("../../../images/resizeIcon.svg") !important;

		&:after {
			display: none;
		}
	}

	.react-grid-layout {
		&.scrollableChart {
			height: 100vh !important;
		}

		.hidden {
			display: none;
		}
	}
}

// graph date range

.graphPopover {
	// top: 375px !important;
	max-width: 355px;
	width: 355px;

	.ant-popover-content {
		width: 100%;

		.ant-popover-arrow {
			display: none;
		}

		.ant-popover-inner {
			padding: 0px 0px;

			.ant-popover-title {
				padding: 20px 24px;
				position: relative;

				&::after {
					position: absolute;
					content: "";
					height: 1px;
					width: 100%;
					background-color: @border-bottom-after;
					left: 0px;
					bottom: 0px;
				}
			}
		}

		.ant-picker {
			width: 100%;
		}

		.popoverFooter {
			display: flex;
			padding: 14px 24px;
			align-items: center;
			justify-content: flex-end;
			position: relative;

			Button {
				height: 42px;
				max-width: 143px;
				margin: 0px 0px 0px 8px;

				&.cancelBtn {
					// width: 50%;
					height: 42px;
					background-color: rgba(74, 92, 251, 0.1);
					margin: 0px 10px 0px 24px;
					color: @grey-color;
					cursor: pointer;
					.transition();

					&:hover {
						background: #4a5cfb33;
					}
				}
			}

			&::after {
				position: absolute;
				content: "";
				height: 1px;
				width: 100%;
				background-color: @border-bottom-after;
				left: 0px;
				top: 0px;
			}
		}
	}

	.ant-picker {
		background: @lighgrey-color;
		height: 50px;
		border-radius: 12px;
		border: none;
		color: #000;
	}
}

// drawer

.graphDrawer {
	height: 100%;

	// height:calc(87vh - 100px);
	// top:228px;
	// .ant-drawer-mask {
	//     left:200px;
	// }
	.ant-drawer-header {
		border: none;
		padding-bottom: 0;

		.ant-drawer-close {
			position: absolute;
			right: 0;
		}

		.ant-drawer-header-title {
			.ant-drawer-title {
				.semiMediumFont();
				font-size: 12px;
				color: @grey-color;
			}
		}
	}

	.ant-drawer-body {
		padding: 0px 24px 24px 24px;
	}

	.ant-row {
		.ant-form-item {
			width: 100%;
		}
	}

	.ant-select-selector {
		background-color: #f2f3f7 !important;
		border: none !important;
		box-shadow: none !important;
	}

	.graphHeading {
		font-family: @primary-font;
		font-size: 12px;
		font-weight: 400;
		color: #676a78;
	}

	.ant-form-item {
		position: relative;
		margin-bottom: 10px;

		.ant-form-item-label {
			padding: 0;
			position: absolute;
			left: 16px;
			top: 5px;
			z-index: 9;
			.transition;

			label {
				font-size: 12px;
				color: @grey-color;
				font-weight: 400;
				font-family: @primary-font;
				height: 100%;
			}
		}

		.ant-form-item-control-input {
			.ant-input {
				font-size: 14px;
				color: @default-color;
				font-weight: 600;
				font-family: @primary-font;
				height: 50px;
				border: none;
				display: flex;
				flex-wrap: wrap;
				padding: 20px 20px 5px 16px;
				align-items: center;
				background-color: @lighgrey-color;
				padding: 0 15px;
				.transition;
				border-radius: 12px;
			}
		}

		.ant-select {
			.ant-select-selector {
				height: 50px;
				font-size: 14px;
				color: @default-color;
				font-weight: 600;
				font-family: @primary-font;
				height: 50px;
				border: none;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				background-color: @lighgrey-color;
				padding: 0 15px;
				border-radius: 12px;

				.ant-select-selection-placeholder {
					font-size: 14px;
					color: @default-color;
					font-weight: 600;
					font-family: @primary-font;
					padding: 15px 0px 0px 0px;
				}

				.ant-select-selection-item {
					top: 0px;
				}
			}
		}

		.ant-input-affix-wrapper {
			border: none;
			font-size: 14px;
			color: @default-color;
			font-weight: 600;
			font-family: @primary-font;
			height: 50px;
			width: 100%;
			border: none;
			display: flex;
			flex-wrap: nowrap;
			padding: 0px 0px 0px 0px;
			align-items: center;
			background-color: @lighgrey-color;
			.transition;
			border-radius: 12px;

			.ant-input {
				padding: 0px 0px 0px 20px !important;
				width: 80%;
			}

			.ant-input-suffix {
				width: 20%;

				.badgeBtn {
					width: 100%;
					display: flex;
					align-items: center;
					// justify-content: space-between;

					button {
						border: none;
						background-color: transparent;
						padding: 0px 0px;
						// margin-left: 10px;
					}

					.color-picker-input-design {
						.ant-form-item-control-input-content {
							div {
								width: 22px !important;
								height: 22px !important;
								border-radius: 50%;
								border: none !important;
								margin-bottom: -10px;
							}
						}

						&.ant-form-item {
							width: 50% !important;
						}
					}
				}
			}

			&.ant-input-affix-wrapper-focused {
				box-shadow: 0 0 0 1px rgb(74 92 251);
			}

			&:focus {
				background: #4a5cfb33;
				color: @primary-color;
			}
		}

		.ant-picker {
			height: 50px;
			border: none;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			background-color: @lighgrey-color;
			// padding: 0 15px;
			.transition;
			border-radius: 12px;

			.ant-picker-input {
				input {
					font-size: 14px;
					color: @default-color;
					font-weight: 600;
					padding: 18px 20px 5px 5px !important;
					font-family: @primary-font;
				}
			}

			.ant-picker-suffix {
				color: @primary-color;
			}
		}

		textarea {
			min-height: 130px;
			resize: none;
			padding: 15px !important;
		}
	}

	.ant-form-item-control-input {
		.ant-input {
			font-size: 14px;
			color: @default-color;
			font-weight: 600;
			font-family: @primary-font;
			height: 50px;
			border: none;
			display: flex;
			flex-wrap: wrap;
			padding: 20px 20px 5px 16px;
			align-items: center;
			background-color: @lighgrey-color;
			padding: 0 15px;
			.transition;
			border-radius: 12px;
		}
	}

	.drawerchartOptions {
		&.ant-collapse {
			margin-bottom: 10px;
			border: none;
			background-color: @white;

			.ant-collapse-item {
				border: none;
			}

			.ant-collapse-header {
				font-size: 12px;
				color: @grey-color;
				.semiMediumFont();
				padding: 18px 16px;
				line-height: 1.4;
				height: 50px;
				background-color: @lighgrey-color;
				border-radius: 12px;
				border: none;

				.ant-collapse-expand-icon {
					svg {
						font-size: 14px;

						path {
							fill: @primary-color;
						}
					}
				}
			}

			.ant-collapse-content {
				border: none;
				background-color: @white;
				padding: 0 16px;
				margin-top: 8px;
				box-shadow: 0px 4px 20px rgb(40 43 54 / 8%);
				border-radius: 10px;

				.ant-collapse-content-box {
					padding: 0;
				}

				.submenuList {
					margin: 0;
					padding: 0;

					li {
						list-style: none;
						margin: 13px 0px;
						display: inline-block;
						width: 100%;

						a {
							position: relative;
							padding-left: 32px;
							font-size: 14px;
							font-family: @primary-font;
							color: @default-color;
							font-weight: 600;

							svg {
								position: absolute;
								left: 0;
								top: 0;

								path {
									.transition;
								}
							}

							&:hover {
								color: @primary-color;

								svg {
									path {
										fill: @primary-color;
									}
								}
							}
						}

						&.pieChart {
							:hover {
								svg {
									path {
										stroke: @primary-color;
										fill: transparent;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.ant-drawer-footer {
		padding: 20px 16px;

		.footerIcons {
			display: flex;
			align-items: center;
			justify-content: space-evenly;

			button {
				display: flex;
				align-items: center;
				border: none;
				background: transparent;
				cursor: pointer;
				.transition();
				color: @grey-color;
				font-size: 16px;
				.mediumFont();
				box-shadow: none;

				.svgIcon {
					margin-right: 13px;

					svg {
						height: 22px;
						width: 22px;
						vertical-align: bottom;
					}
				}

				&:hover {
					svg {
						path {
							fill: @primary-color;
						}
					}

					color: @primary-color;
				}

				&.deleteBtn {
					// &:hover {
					//     svg {
					//         path {
					//             fill: @colorPink;
					//         }
					//     }

					//     color:@colorPink;

					// }
					.archiveBtn {
						svg {
							&:hover {
								path {
									fill: @primary-color;
								}
							}
						}

						span {
							padding-left: 10px;
						}
					}
				}
			}
		}
	}

	.addMetrics {
		margin: 0;
		font-size: 16px;
		font-weight: 600;
		color: @primary-color;
		background-color: rgba(74, 92, 251, 0.1);
		height: 50px;
		margin-bottom: 10px;
		width: 100%;
		display: flex;
		align-items: center;
		box-shadow: none;
		.transition();

		&:hover {
			background: #4a5cfb33;
			color: @primary-color;
		}

		&:focus {
			background: #4a5cfb33;
			color: @primary-color;
		}
	}

	.ant-row {
		.leads {
			font-size: 14px;
			color: @default-color;
			.semiMediumFont();
			height: 50px;
			border: none;
			display: flex;
			flex-wrap: wrap;

			align-items: center;
			background-color: #f2f3f7;
			padding: 0 15px;
			border-radius: 12px;
			width: 100%;
			margin-bottom: 10px;
			justify-content: space-between;

			button {
				border: none;
				background-color: transparent;

				svg {
					height: 24px;
					width: 24px;
				}
			}

			.ant-badge {
				.ant-badge-status-dot {
					height: 22px;
					width: 22px;
				}
			}

			.badgeBtn {
				display: flex;
				align-items: center;
			}
		}
	}

	h4 {
		.semiMediumFont();
		font-size: 12px;
		color: @grey-color;
		margin-bottom: 10px;
	}

	.ant-divider {
		margin: 0px 0px 10px 0px;
	}

	.tabTrade {
		margin-top: 7px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			color: @default-color;
			.semiMediumFont();
			font-size: 14px;
		}
	}

	.timeFrameData {
		height: 135px;
		overflow: auto;
		margin: 16px 0px;

		.ant-col {
			height: 58px;
		}

		.greyInput {
			background-color: @lighgrey-color;
			height: 50px;
			width: 100%;
			display: flex;
			padding: 0px 16px;
			align-items: center;
			justify-content: space-between;
			border-radius: 12px;
			margin-bottom: 10px;
			cursor: pointer;
		}

		.rightBtn {
			display: flex;
			align-items: center;
			height: 48px;

			.ant-form-item {
				height: 50px;

				&.color-picker-input-design {
					height: 50px;
					margin-bottom: 0 !important;
				}
			}

			button {
				height: 50px;
				line-height: 0.5;
			}
		}

		span {
			font-family: @primary-font;
			font-weight: 600;
			font-size: 14px;
		}

		.color-picker-input-design {
			.ant-form-item-control-input-content {
				margin: 13px 10px 0px 0px;
				cursor: pointer;

				div {
					box-shadow: none !important;
					border: none !important;
					height: 25px !important;
					width: 25px !important;
					border-radius: 50%;
				}
			}
		}

		button {
			border: none;
			padding: 0px 0px;
			cursor: pointer;

			svg {
				path {
					fill: @primary-color;
				}
			}
		}
	}

	.selectGraph {
		height: 150px;
		overflow: auto;

		.greyInput {
			background-color: @lighgrey-color;
			height: 50px;
			width: 100%;
			display: flex;
			padding: 0px 16px;
			align-items: center;
			justify-content: space-between;
			border-radius: 12px;
			margin-bottom: 10px;

			.rightBtn {
				display: flex;
				align-items: center;

				button {
					background-color: transparent;
					border: none;
					cursor: pointer;
					padding: 0px 0px;

					svg {
						path {
							fill: @primary-color;
						}
					}
				}
			}

			button {
				background-color: transparent;
				border: none;
				cursor: pointer;
				padding: 0px 0px;

				svg {
					path {
						fill: @primary-color;
					}
				}
			}

			span {
				font-family: @primary-font;
				font-weight: 600;
				font-size: 14px;
			}

			.color-picker-input-design {
				.ant-form-item-control-input-content {
					margin: 13px 10px 0px 0px;
					cursor: pointer;

					div {
						box-shadow: none !important;
						border: none !important;
						height: 25px !important;
						width: 25px !important;
						border-radius: 50%;
					}
				}
			}
		}
	}
}

.addMetricsPopover {
	width: 380px !important;

	.ant-popover-inner {
		padding: 25px;

		.ant-popover-title {
			padding: 0px 0px;
			color: @grey-color;
			.semiMediumFont();
			font-size: 12px;
		}

		.ant-popover-inner-content {
			padding: 0px 0px;
			width: 100%;
		}
	}

	.ant-row {
		.ant-input-group-wrapper {
			border-radius: 12px;

			.ant-input-wrapper {
				height: 50px;
				border: none;

				.ant-input {
					height: 50px;
					border: none;
					background: @lighgrey-color;
					border-radius: 12px;
				}
			}
		}

		.ant-input-group-addon {
			display: none;

			button {
				background-color: @lighgrey-color;
				box-shadow: none;
			}
		}
	}

	.ant-collapse {
		width: 100%;
		border: none;
		background-color: transparent;

		.ant-collapse-item {
			.ant-collapse-header {
				border-bottom: none;
				padding: 12px 0px;

				.ant-collapse-header-text {
					.mediumFont();
					font-size: 16px;
					color: @default-color;
				}
			}

			.ant-collapse-content {
				border-top: none;
				height: 100%;
				overflow: auto;
			}
		}

		.ant-collapse-content-box {
			padding: 0px 0px;
		}

		.panelContent {
			font-size: 16px;
			color: @default-color;
			.semiMediumFont();
			height: 50px;
			border: none;
			display: flex;
			flex-wrap: wrap;

			align-items: center;
			background-color: #f2f3f7;
			padding: 0 15px;
			border-radius: 12px;
			width: 100%;
			margin-bottom: 10px;
			justify-content: space-between;

			.leadNumber {
				.mediumFont();
			}

			.panelTitle {
				height: 100%;
				word-break: break-all;
				/* overflow: auto; */
				margin-right: 10px;
			}
		}
	}

	.popoverCollapse {
		height: 300px;
		overflow: auto;
		width: 100%;

		.panelContent {
			background-color: @lighgrey-color;
			height: 50px;
			width: 100%;
			display: flex;
			padding: 0px 16px;
			align-items: center;
			justify-content: space-between;
			border-radius: 12px;
			margin-bottom: 10px;
			cursor: pointer;

			.greyInput {
				cursor: pointer;
			}
		}
	}

	.searchBtn {
		background-color: transparent;
		width: auto;
		position: absolute;
		right: 34px;
		padding: 0px 0px;
		z-index: 99;
		top: 70px;

		&:hover {
			background-color: transparent;
		}
	}

	.ant-input-affix-wrapper {
		padding: 0px 0px;
		border: none;
		height: 50px;
		border: none;
		background: #f2f3f7;
		border-radius: 12px !important;
		padding: 0px 16px;
	}

	.anticon {
		svg {
			margin-right: 20px;
		}
	}
}

.selectedAll {
	width: 100%;
	height: 55px;
	font-size: 14px;
	color: @default-color;
	font-weight: 600;
	font-family: @primary-font;
	border: none;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background-color: @lighgrey-color;
	padding: 0 15px;
	border-radius: 12px;
	// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	//  border: none;
	//     .ant-select-selection-search{

	//         .ant-select-selection-search-input{
	//             height: 50px;
	//     font-size: 14px;
	//     color: @default-color;
	//     font-weight: 600;
	//     font-family: @primary-font;

	//         }
	//     }

	//     &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector{
	//         box-shadow: none !important;
	//     }
	// }

	.ant-select-selection-item {
		top: 10px;
		left: -12px;
		color: #000;
		font-weight: 600;
	}
}

.metricTitle {
	position: absolute;
	z-index: 99;
	left: 37px;
	margin-top: 6px;
	font-size: 12px;
}

.ant-select-selector {
	border: none;
}

.headingRow {
	.ant-col {
		padding: 10px;
		font-size: 16px;
		font-family: @primary-font;
		color: @grey-color;
		font-weight: 600;
	}
}

.dataRow {
	.ant-col {
		padding: 5px 10px;
		font-size: 16px;
		font-family: @primary-font;
		color: @black-color;
		font-weight: 400;
	}

	.currentData {
		margin: 0px 8px;
	}
}

.diffrenceGraphContainer {
	.ant-divider {
		margin: 0px 0px;
	}
}

.lineWithPointContainer {
	width: 100%;
	height: 100%;
}

// date range

.graphDateRange {
	.ant-picker-dropdown {
		.ant-picker-panel-container {
			display: flex;
			flex-direction: row-reverse;
			padding: 24px;
		}

		// .ant-picker-panels {
		//     width: calc(100% - 120px);
		// }
	}

	.ant-picker-panel-container {
		padding: 15px 15px 60px 15px;
		position: relative;
		display: flex;
		flex-direction: row-reverse;

		.ant-picker-panels {
			.ant-picker-date-panel {
				.ant-picker-header {
					button {
						color: #000;
						font-weight: 600;
					}
				}
			}
		}

		.ant-picker-footer {
			width: 20% !important;
			min-width: 20% !important;
			padding-left: 10px;
			position: relative;

			.ant-picker-ranges {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 100%;

				.ant-picker-preset {
					.ant-tag {
						background-color: transparent;
						border: none;
						color: #000;
						.semiMediumFont();
						font-size: 13px;
						padding: 4px 0px;
						margin-top: 0px;
					}
				}
			}

			&:after {
				position: absolute;
				content: "";
				background-color: #f2f3f7;
				height: 100%;
				width: 1px;
				top: 0;
				right: 0;
			}
		}

		.popoverFooter {
			position: absolute;
			bottom: -50px;
			display: flex;
			left: 150px;

			button {
				padding: 8px 30px;
				background-color: #f2f3f7;
				color: @grey-color;
				width: 140px;
				margin-right: 10px;
			}

			.blueSave {
				background-color: @primary-color;
				color: @white;
			}
		}
	}

	.ant-picker-body {
		.ant-picker-content {
			thead {
				tr {
					th {
						color: #676a78;
						font-weight: 600;
					}
				}
			}

			tbody {
				tr {
					td {
						.ant-picker-cell-inner {
							color: #000;
							font-weight: 500;
							font-family: @primary-font;
						}
					}
				}
			}
		}
	}
}


// date range for popover
.goalRangePopup {
	.ant-picker-panel-container {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;

		.ant-picker-ranges {
			display: flex;
			align-items:flex-end;
			justify-content:flex-end;
			flex-direction: column;
		}
	}
}
